import {
  PaymentMethod,
  PaymentMethods,
  PaymentMethodsEnglish
} from './payment';
import { PaymentGateways } from './payment-gateways.model';

export const PERSON_TYPES = [
  { name: 'Persona Jurídica', id: 'association', idTypes: ['NIT'] },
  { name: 'Persona Natural', id: 'individual', idTypes: ['CC', 'CE'] }
];

export const ID_TYPES = PERSON_TYPES.reduce(
  (types, person) => [...types, ...person.idTypes],
  [] as Array<string>
);

export interface PaymentMethodByCountry {
  country: string;
  flag: string;
  isoCode: string;
  paymentMethods: PaymentMethod[];
  visible: boolean;
}
export const PAYMENT_METHODS_BY_COUNTRY: PaymentMethodByCountry[] = [
  {
    country: 'Colombia',
    flag: '🇨🇴',
    isoCode: 'CO',
    paymentMethods: [
      {
        name: PaymentMethods.CARD,
        nameEn: PaymentMethodsEnglish.CARD,
        icon: 'credit_card',
        gateway: PaymentGateways.STRIPE,
        currency: 'COP'
      },
      {
        name: PaymentMethods.TRANSFER_PSE,
        nameEn: PaymentMethodsEnglish.TRANSFER_PSE,
        icon: 'account_balance',
        gateway: PaymentGateways.MERCADOPAGO,
        currency: 'COP',
        minimum: 1600
      },
      {
        name: PaymentMethods.CASH_EFECTY,
        nameEn: PaymentMethodsEnglish.CASH_EFECTY,
        icon: 'money',
        gateway: PaymentGateways.MERCADOPAGO,
        currency: 'COP',
        minimum: 5000,
        maximum: 8000000
      }
    ],
    visible: true
  },
  {
    country: 'México',
    flag: '🇲🇽',
    isoCode: 'MX',
    paymentMethods: [
      {
        name: PaymentMethods.CARD,
        nameEn: PaymentMethodsEnglish.CARD,
        icon: 'credit_card',
        gateway: PaymentGateways.STRIPE_MX,
        currency: 'MXN'
      },
      {
        name: PaymentMethods.TRANSFER_SPEI,
        nameEn: PaymentMethodsEnglish.TRANSFER_SPEI,
        icon: 'account_balance',
        gateway: PaymentGateways.STRIPE_MX,
        currency: 'MXN'
      },
      {
        name: PaymentMethods.CASH_OXXO,
        nameEn: PaymentMethodsEnglish.CASH_OXXO,
        icon: 'money',
        gateway: PaymentGateways.STRIPE_MX,
        currency: 'MXN',
        minimum: 10,
        maximum: 10000
      }
    ],
    visible: true
  },
  {
    country: 'Otro (Worldwide)',
    flag: '🌎',
    isoCode: 'US',
    paymentMethods: [
      {
        name: PaymentMethods.CARD,
        nameEn: PaymentMethodsEnglish.CARD,
        icon: 'credit_card',
        gateway: PaymentGateways.STRIPE,
        currency: 'USD'
      }
    ],
    visible: true
  }
];
