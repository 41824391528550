/**
 * Set hour to 23:59:59 GMT-5
 */
export const setHours = (date: Date): Date => {
  const minutesInAnHour = 60;
  const offset = date.getTimezoneOffset() / minutesInAnHour;
  const hour = 23;
  const minutes = 59;
  const seconds = 59;
  const timezone = 5;
  date.setHours(hour + (timezone - offset), minutes, seconds, 0);
  return date;
};
