import { User } from '../users';
import { BankAccount, Message, Vaki } from '../vakis';

export const MINIMUM_WITHDRAW_COP = 90000;
export const MINIMUM_WITHDRAW_USD = 90;
export const MINIMUM_FREE_WITHDRAW_COP = 500000;
export const MINIMUM_FREE_WITHDRAW_USD = 500;
export const WITHDRAW_COST_COP = 10000;
export const WITHDRAW_COST_USD = 10;

export interface Withdraw {
  currency: string;
  date: number;
  state: WithdrawState;
  transactionId: string;
  user: {
    key: User['uid'];
    email: string;
  };
  accountKey: BankAccount['key'];
  vakiKey: Vaki['key'];
  urgent?: boolean;
  urgencyReason?: string;
  value: number;
  bank?: WithdrawBanks | null;
}

export enum WithdrawState {
  REQUESTED = 'Solicitada',
  PENDING = 'Pendiente',
  STARTED = 'Iniciada',
  ON_GOING = 'En curso',
  COMPLETE = 'Completada',
  MISSING_INFORMATION = 'Información requerida',
  ARCHIVED = 'Archivada'
}
export const WITHDRAW_STATES: WithdrawState[] = [];
const isValidState = (value: string): value is keyof typeof WithdrawState =>
  value in WithdrawState;
for (const state in WithdrawState) {
  if (isValidState(state)) {
    WITHDRAW_STATES.push(WithdrawState[state]);
  }
}

export enum WithdrawBanks {
  BANCO_DAVIVIENDA = 'Banco Davivienda',
  BANCO_COLPATRIA = 'Banco Colpatria',
  BANK_OF_AMERICA = 'Bank Of América',
  PAYPAL = 'PayPal',
  GLOBAL_66 = 'Global66',
  BBVA_MEXICO = 'BBVA México'
}
export const WITHDRAW_BANKS: WithdrawBanks[] = [];
const isValidBank = (value: string): value is keyof typeof WithdrawBanks =>
  value in WithdrawBanks;
for (const bank in WithdrawBanks) {
  if (isValidBank(bank)) {
    WITHDRAW_BANKS.push(WithdrawBanks[bank]);
  }
}

export interface WithdrawMessage extends Message {
  state: WithdrawState;
  fileUrl?: string;
  value?: number;
}
