export interface Brand {
  id: BRANDS_IDS;
  displayName: string;
  description: string;
  priority: number;
}
export enum BRANDS_IDS {
  POLITICS = 'politics',
  SPONSOR = 'sponsor',
  STARTER = 'starter',
  IMPACTO = 'impacto',
  GIFTS = 'gifts',
  FUN = 'fun',
  SOCIAL = 'social'
}
export enum CATEGORY_IDS {
  NoProfit = 'no-profit'
}
export const BRANDS = {
  POLITICS: {
    id: 'politics',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-politics-black.svg',
      alt: 'Logo Vaki politics'
    }
  },
  SPONSOR: {
    id: 'sponsor',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-sponsor-black.svg',
      alt: 'Logo Vaki sponsor'
    }
  },
  STARTER: {
    id: 'starter',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-starter-black.svg',
      alt: 'Logo Vaki starter'
    }
  },
  IMPACTO: {
    id: 'impacto',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-impacto-black.svg',
      alt: 'Logo Vaki impacto'
    }
  },
  GIFTS: {
    id: 'gifts',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-gifts-black.svg',
      alt: 'Logo Vaki gifts'
    }
  },
  FUN: {
    id: 'fun',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-fun-black.svg',
      alt: 'Logo Vaki fun'
    }
  },
  SOCIAL: {
    id: 'social',
    logo: {
      img: 'assets/images/logos/brands/black/vaki-social-black.svg',
      alt: 'Logo Vaki social'
    }
  }
};
