export function getBrandLogo(
  brand: string,
  color: 'white' | 'black' | 'green'
): string {
  return `assets/images/logos/brands/${color}/vaki-${brand}-${color}.svg`;
}
export function getBrandSimpleLogo(
  brand: string,
  color: 'white' | 'black' | 'green'
): string {
  return `assets/images/logos/brands-simple/${brand}-${color}.svg`;
}
