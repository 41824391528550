import { MercadopagoApiResponse, MercadopagoPayment } from '../mercadopago';
import { User, UserAddress } from '../users';
import {
  BRANDS_IDS,
  RewardBase,
  RewardItems,
  RewardPropsSelected,
  Vaki
} from '../vakis';
import { PaymentGateways } from './payment-gateways.model';
import { PoliticsSignature } from './politics-signature.model';

export interface Payment {
  costs?: {
    gatewayTaxes?: number;
    other_cost?: number;
    gatewayFee?: number;
    comision?: number;
  };
  tip?: number;
  payerInfo: PaymentUserInfo;
  anonymous?: boolean;
  currency: string;
  gateway: PaymentGateways;
  key: string;
  rewardKey: RewardBase['key'] | RewardTypes;
  rewardPlan?: string;
  rewards?: RewardItems | null;
  vakiKey: Vaki['key'];
  vakiName: string;
  vakiBrand: BRANDS_IDS | '';
  vakiCountry: string;
  vakiCategory: string;
  method: PaymentMethods;
  state: PaymentState;
  gatewayResponse: string;
  gatewayId?: string;
  attachment?: string;
  value: number;
  date: number;
  customCrm?: {
    paymentId: number;
    token: string;
  };
  supportNumber?: number;
  signatureReady?: boolean;
  message?: PaymentMessage;
  donationCertificate?: {
    requestTime: number;
    url: string;
  };
}
export enum RewardTypes {
  Free = 'libre',
  Multiple = 'multiple'
}
export interface PaymentMessage {
  content: string;
  isPrivate: boolean;
}

export interface PaymentSubCollections {
  paymentDeilvery?: PaymentDelivery;
  politicsSignature?: PoliticsSignature;
}
export enum PaymentState {
  PAYMENT_APPROVED = 1,
  PAYMENT_REJECTED,
  PAYMENT_PENDING,
  PAYMENT_APPROVED_BUT_PENDING,
  PAYMENT_REJECTED_BY_CREATOR
}

export interface PaymentUserInfo {
  idUser: User['uid'];
  name: string;
  address?: string;
  city?: string;
  country?: string;
  phone?: string;
  document?: { type: string; id: string };
  email: string;
}

export interface PaymentDelivery {
  key: string;
  address?: UserAddress;
  rewardsProps?: RewardPropsSelected[];
}

export interface NewPaymentResult {
  mp: MercadopagoApiResponse;
  db: Payment;
  error: {
    message: string;
  };
}

export interface NewPaymentData {
  dbPayment: Payment;
  mpPayment?: MercadopagoPayment;
  stripePayment?: MercadopagoPayment;
}

export enum PaymentMethods {
  CC_VISA_NEW = 'Nueva tarjeta de crédito Visa',
  CC_MASTERCARD_NEW = 'Nueva tarjeta de crédito Mastercard',
  CC_NEW = 'Nueva tarjeta de crédito',
  CC_DC_NEW = 'Nueva tarjeta de crédito o débito',
  CARD = 'Tarjeta de crédito o débito',
  CC_VISA_SAVED = 'Tarjeta de crédito guardada Visa',
  CC_MASTERCARD_SAVED = 'Tarjeta de crédito guardada Mastercard',
  CC_SAVED = 'Tarjeta de crédito guardada',
  TRANSFER_PSE = 'Transferencia bancaria con PSE',
  TRANSFER_SPEI = 'Transferencia bancaria con SPEI',
  CASH_EFECTY = 'Pago en efectivo con Efecty',
  CASH_OXXO = 'Pago en efectivo con Oxxo',
  CASH_BCP = 'Pago en efectivo con BCP',
  CASH_PAGOFACIL = 'Pago en efectivo con PAGOFACIL',
  CASH_PAGOEFECTIVO = 'Pago en efectivo con Pago en efectivo',
  CASH_CASH = 'Pago en efectivo',
  DIRECT_DEPOSIT = 'Consignación directa al creador',
  VAKI_DEPOSIT = 'Consignación directa a Vaki',
  APPLE_PAY = 'Apple Pay',
  GOOGLE_PAY = 'Google Pay'
}

export enum PaymentMethodsEnglish {
  CC_VISA_NEW = 'New Visa credit card',
  CC_MASTERCARD_NEW = 'New Mastercard credit card',
  CC_NEW = 'New credit card',
  CC_DC_NEW = 'New credit or debit card',
  CARD = 'Credit or debit card',
  CC_VISA_SAVED = 'Saved Visa credit card',
  CC_MASTERCARD_SAVED = 'Saved Mastercard credit card',
  CC_SAVED = 'Saved credit card',
  TRANSFER_PSE = 'Bank transfer with PSE',
  TRANSFER_SPEI = 'Bank transfer with SPEI',
  CASH_EFECTY = 'Cash payment with Efecty',
  CASH_OXXO = 'Cash payment with Oxxo',
  CASH_BCP = 'Cash payment with BCP',
  CASH_PAGOFACIL = 'Cash payment with PAGOFACIL',
  CASH_PAGOEFECTIVO = 'Cash payment with Pago en efectivo',
  CASH_CASH = 'Cash payment with ',
  DIRECT_DEPOSIT = 'Direct transfer to creator',
  VAKI_DEPOSIT = 'Direct transfer to Vaki',
  APPLE_PAY = 'Apple Pay',
  GOOGLE_PAY = 'Google Pay'
}

export interface PaymentMethod {
  name: PaymentMethods;
  nameEn: PaymentMethodsEnglish;
  icon: string;
  gateway: PaymentGateways;
  currency: string;
  minimum?: number;
  maximum?: number;
}

export enum CashPaymentLogos {
  OXXO = 'https://firebasestorage.googleapis.com/v0/b/vaquinha-dev.appspot.com/o/APPS%2Fvaki%2Foxxo-logo-vector%201.png?alt=media&token=b5260ea3-4cc4-4a69-8446-dc190ede23e6',
  efecty = 'https://firebasestorage.googleapis.com/v0/b/vaquinha-dev.appspot.com/o/APPS%2Fvaki%2Fefecty%201.png?alt=media&token=ef3860f4-6efa-400a-b045-fdc19af0a4bd'
}
