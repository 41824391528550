import {
  PayUCardMethods,
  PayUCashMethods,
  PayUIdType,
  PayUPaymentMethods
} from './payu.types';
import { SubscriptionPlan } from '../vakis';

export interface PayUPayment {
  language?: string;
  command?: string;
  merchant?: {
    apiKey: string;
    apiLogin: string;
  };
  transaction: {
    order: {
      accountId: string;
      referenceCode: string;
      description: string;
      language: string;
      signature: string;
      notifyUrl: string;
      additionalValues: {
        TX_VALUE: {
          value: number;
          currency: string;
        };
        TX_TAX?: {
          value: number;
          currency: string;
        };
        TX_TAX_RETURN_BASE?: {
          value: number;
          currency: string;
        };
      };
      buyer?: {
        merchantBuyerId: string;
        fullName: string;
        emailAddress: string;
        contactPhone: string;
        dniNumber: string;
        shippingAddress: {
          street1: string;
          street2: string;
          city: string;
          state: string;
          country: string;
          postalCode: string;
          phone: string;
        };
      };
      shippingAddress?: {
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        postalCode: string;
        phone: string;
      };
    };
    payer: {
      merchantPayerId?: string;
      fullName: string;
      emailAddress: string;
      contactPhone?: string;
      dniNumber: string;
      billingAddress?: {
        street1: string;
        street2: string;
        city: string;
        state: string;
        country: string;
        postalCode: string;
        phone: string;
      };
    };
    creditCardTokenId?: string;
    creditCard: PayUCreditCard;
    extraParameters: {
      INSTALLMENTS_NUMBER: number;
    };
    type: string;
    paymentMethod: string;
    paymentCountry: string;
    deviceSessionId?: string;
    ipAddress: string;
    cookie: string;
    userAgent: string;
  };
  test?: boolean;
}

export interface PayUResponse {
  code: 'SUCCESS' | 'ERROR';
  error: string;
  transactionResponse: {
    orderId: number;
    transactionId: string;
    state: string;
    paymentNetworkResponseCode: string;
    paymentNetworkResponseErrorMessage: string;
    trazabilityCode: string;
    authorizationCode: string;
    pendingReason: string;
    responseCode: string;
    errorCode: string;
    responseMessage: string;
    transactionDate: string;
    transactionTime: string;
    operationDate: string;
    extraParameters?: {
      BANK_URL?: string;
      URL_PAYMENT_RECEIPT_HTML?: string;
    };
  };
}

export interface PayUPaymentParamsCreditCard {
  creditCardTokenId: string | undefined;
  params: PayUPaymentParams;
}

export interface PayUPaymentParams {
  value: string;
  currency: string;
  description: string;
  key: string;
  name: string;
  email: string;
  callbackUrl: string;
  userIp: string;
  financialId: string;
  personType: string;
  personIdType: PayUIdType;
  personIdNumber: string;
  userCookie: string;
  userAgent: string;
  creditCard?: PayUCreditCard;
  paymentMethod: PayUPaymentMethods;
  paymentCountry: PayuCountryAccounts;
  expirationDate?: string;
}

export interface PayUBanksResponse {
  code: 'SUCCESS' | 'ERROR';
  error: null;
  banks: PayUBank[];
}

export interface PayUBank {
  id: string;
  description: string;
  pseCode: string;
}

export interface PayUPseForm {
  bank: PayUBank;
  name: string;
  entity: {
    personType: string;
    personIdType: PayUIdType;
  };
  personIdNumber: string;
  userCookie: string;
  userAgent: string;
  ip: string;
  paymentCountry: string;
}

export const PAYU_ENTITY_GROUPS = [
  {
    entityType: { name: 'Persona Natural', id: 'N' },
    identificationType: ['CC', 'TI', 'CE', 'DNI', 'IFE/INE', 'CURP']
  },
  {
    entityType: { name: 'Persona Jurídica (Empresas)', id: 'J' },
    identificationType: ['NIT']
  }
];

export const PAYU_CARDS_TYPES = [
  { name: 'VISA', id: 'VISA' },
  { name: 'MASTERCARD', id: 'MASTERCARD' },
  { name: 'AMEX', id: 'AMEX' },
  { name: 'DINERS', id: 'DINERS' },
  { name: 'CODENSA', id: 'CODENSA' }
];

export interface PayUCreditCardForm {
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardholderName: string;
  cardNumber: string;
  docNumber: string;
  entity: {
    personType: string;
    personIdType: PayUIdType;
  };
  securityCode: string;
  userCookie: string;
  userAgent: string;
  ip: string;
  cardType: PayUCardMethods;
  paymentCountry: string;
}

export interface PayUCashForm {
  cashMethod: PayUCashMethods;
  entity: {
    personType: string;
    personIdType: PayUIdType;
  };
  identificationNumber: string;
  name: string;
  ip: string;
  userCookie: string;
  userAgent: string;
  paymentCountry: string;
}

export enum PayUTransactionStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  INVALID_TRANSACTION = 'INVALID_TRANSACTION'
}

export interface PayUCreditCardTokenResponse {
  code: 'SUCCESS' | 'ERROR';
  error: string | undefined;
  creditCardToken: CreditCardToken;
}

export interface CreditCardToken {
  creditCardTokenId: string;
  name: string;
  payerId: string;
  identificationNumber: string;
  paymentMethod: PayUPaymentMethods;
  number: null;
  expirationDate: null;
  creationDate: null;
  maskedNumber: string;
  errorDescription: null;
}

export interface PayUCreditCardTokenRequest {
  payerId: string;
  name: string;
  identificationNumber: string;
  paymentMethod: string;
  number: string;
  expirationDate: string;
}

export interface PayUCreditCard {
  number?: string;
  securityCode?: string;
  expirationDate?: string;
  name?: string;
  processWithoutCvv2?: boolean;
}

export interface PayuSubscriptionParams {
  payUPayment: PayUPaymentParams;
  uid: string;
  rewardPlan: string;
  vakiKey: string;
}

export interface SavePaymentAndCreditCardTokenParams
  extends PayuSubscriptionParams {
  payment: PayUResponse;
  subscriptionPlan: SubscriptionPlan | undefined;
  creditCardToken: CreditCardToken | undefined;
}

export enum PayuCountryAccounts {
  MEXICO = 'MX',
  PERU = 'PE',
  COLOMBIA = 'CO',
  ARGENTINA = 'AR'
}
