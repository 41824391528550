export const LANGUAGES: Language[] = [
  {
    code: 'en',
    name: 'English',
    baseRef: '/en'
  },
  {
    code: 'es',
    name: 'Español',
    baseRef: '/es'
  }
];

export interface Language {
  code: string;
  name: string;
  baseRef: string;
}
