export const MERCADOPAGO_API_BASE_URL = 'https://api.mercadopago.com';
export const ENTITY_GROUPS: MercadopagoEntityGroup[] = [
  {
    entityType: { name: 'Persona Natural', id: 'individual' },
    identificationType: ['CC', 'TI', 'CE', 'DNI']
  },
  {
    entityType: { name: 'Persona Jurídica (Empresas)', id: 'association' },
    identificationType: ['NIT']
  }
];

export interface MercadopagoEntity {
  name: string;
  id: string;
}

export interface MercadopagoEntityGroup {
  entityType: MercadopagoEntity;
  identificationType: string[];
}
