import { Image } from '../';

export const LOGO_WHITE: Image = {
  src: 'assets/images/logos/vaki-logo-white&green.svg',
  alt: 'Logo Vaki Blanco'
};
export const LOGO_FOOTER: Image = {
  src: 'assets/images/logos/logo-vaki-footer.svg',
  alt: 'Logo Vaki footer'
};
export const LOGO_VAKI_SOCIAL: Image = {
  src: 'assets/images/logos/brands/black/vaki-social-black.svg',
  alt: 'Logo Vaki social'
};
export const LOGO_VAKI_GIFTS: Image = {
  src: 'assets/images/logos/brands/black/vaki-gifts-black.svg',
  alt: 'Logo Vaki gifts'
};
