import { NavLink } from '.';
import { GA4ClickEvents } from '../api/helpers/analytics-events';

export interface SideMenu {
  mainNavLinks: NavLink[];
  customComponentNavLinks?: NavLink[];
}

export interface CustomComponentNavLink {
  name: string;
  nameEn: string;
  fragment: string;
  icon: string;
  ga4ClickEvent: GA4ClickEvents;
}

export const ADMIN_MENU_ITEMS: NavLink[] = [
  {
    name: 'Vakis',
    nameEn: 'Vakis',
    icon: 'apps',
    route: '/vakis',
    external: false,
    ga4ClickEvent: GA4ClickEvents.CLICK_MENU_BUTTON
  },
  {
    name: 'Aportes',
    nameEn: 'Aportes',
    icon: 'attach_money',
    route: '/aportes',
    external: false,
    ga4ClickEvent: GA4ClickEvents.CLICK_MENU_BUTTON
  },
  {
    name: 'Transacciones',
    nameEn: 'Transacciones',
    icon: 'payment',
    route: '/transacciones',
    external: false,
    ga4ClickEvent: GA4ClickEvents.CLICK_MENU_BUTTON
  },
  {
    name: 'Usuarios',
    nameEn: 'Usuarios',
    icon: 'person_outline',
    route: '/usuarios',
    external: false,
    ga4ClickEvent: GA4ClickEvents.CLICK_MENU_BUTTON
  },
  {
    name: 'Sites',
    nameEn: 'Sites',
    icon: 'computer',
    route: '/sites',
    external: false,
    ga4ClickEvent: GA4ClickEvents.CLICK_MENU_BUTTON
  },
  {
    name: 'Blogs',
    nameEn: 'Blogs',
    icon: 'rss_feed',
    route: '/blogs',
    external: false,
    ga4ClickEvent: GA4ClickEvents.CLICK_MENU_BUTTON
  }
];
