import { DateTimeFormat } from 'intl';

export const dateText = (dateObject = new Date()): string => {
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();
  if (typeof window === 'undefined') {
    const month = new DateTimeFormat('es-CO', { month: 'long' }).format(
      dateObject
    );
    return `${day} ${
      day > 1 ? 'dias' : 'dia'
    } del mes de ${month} del año ${year}`;
  } else {
    const month = new DateTimeFormat('es-CO', {
      month: 'long',
      timeZone: 'America/Bogota'
    }).format(dateObject);
    return `${day} ${
      day > 1 ? 'dias' : 'dia'
    } del mes de ${month} del año ${year}`;
  }
};
