export enum AlgoliaEvents {
  VAKI_VIEWED = 'vakiViewed',
  VAKI_CONVERTED = 'vakiConverted',
  FILTERS_CLICKED = 'filtersClicked',
  VAKI_SELECTED_HOME = 'vakiSelectedFromHome',
  VAKI_SELECTED_HOME_ALMOST_FINISHED = 'vakiSelectedFromHomeAlmostFinished',
  VAKI_SELECTED_HOME_FIRST_IMPULSE = 'vakiSelectedFromHomeFirstImpulse',
  VAKI_SELECTED_SEARCH = 'vakiSelectedFromSearch',
  VAKI_SELECTED_RECOMMEND = 'vakiSelectedFromRecommend',
}
