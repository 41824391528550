export enum Collections {
  BRANDS = '_brands', //Vaki brands
  BLOGS = 'blogs', // Vaki blog posts
  CATEGORIES = '_categories', //Vaki categories
  COUNTRIES = '_countries', //Vaki countries
  RATES = '_rates', //Vaki rates per country
  STATIC = '_static', //Vaki static assets
  ANALYTICS = 'analytics', //Vaki analytics
  PAYMENTS = 'payments', //Vaki payments
  SITES = 'sites', // Vaki sites.
  USERS = 'users', //Vaki Users
  VAKIS = 'vakis', //Vaki Documents
  WITHDRAWS = 'withdraws' //Vaki withdraws
}

export enum VakiSubCollections {
  UPDATES = 'vaki_updates',
  REWARDS = 'vaki_rewards',
  GIFTS = 'vaki_gifts',
  SUBSCRIPTION_PLANS = 'vaki_subscriptions_plans',
  BANK_ACCOUNTS = 'vaki_bank_accounts',
  ANALYTICS = 'vaki_analytics',
  ACCOUNTING = 'vaki_accounting',
  MESSAGES = 'vaki_messages',
  CONDITIONS = 'vaki_conditions',
  DOCUMENTS = 'vaki_documents',
  SIGNATURES = 'payments_signatures',
  ADMIN_NOTES = 'vaki_admin_notes',
  DONATION_CERTIFICATE = 'vaki_donation_certificate'
}

export enum UserSubCollections {
  BANK_ACCOUNTS = 'user_bank_accounts',
  MERCADOPAGO = 'user_mercadopago_info',
  STRIPE_INFO = 'user_stripe_info',
  PAYU_INFO = 'user_payu_info',
  DATA_AGGREGATE = 'user_data_aggregate',
  SUBSCRIPTIONS = 'user_subscriptions',
  SIGNATURES = 'user_signatures'
}

export enum BrandsSubCollections {
  CATEGORIES = 'categories'
}

export enum WithdrawsSubCollections {
  MESSAGES = 'withdraws_messages'
}

export enum VakiSiteSubCollections {
  STATIC = '_static'
}
export enum PaymentsSubCollections {
  PAYMENT_SIGNATURE = 'payment_signature',
  DELIVERY = 'payments_delivery'
}
