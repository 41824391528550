import { round } from './round';

/**
 * Exchange rates.
 */
export const exchange = (
  actualRates: Record<string, number>,
  from: string,
  to: string,
  value: number,
) => {
  // Initial currency to USD convertion rate
  const fromUsdConvertion = actualRates[from] ?? 1;
  // Final currency to USD convertion rate
  const usdToConvertion = actualRates[to] ?? 1;
  // Initial currency to final currency convertion
  return parseFloat(
    round((value / fromUsdConvertion) * usdToConvertion).toFixed(2),
  );
};
