export interface BanksList {
  country: string;
  countryId?: string;
  nameTaxpayerIdentification?: string;
  banks: Bank[];
}

export interface Bank {
  name: string;
  swift_code: string;
  davivienda_code?: number;
}

export const BANKS: BanksList[] = [
  {
    country: 'argentina',
    countryId: 'ar',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'SEDESA',
        swift_code: ''
      },
      {
        name: 'ABN AMRO BANK N. V.',
        swift_code: ''
      },
      {
        name: 'BACS BANCO DE CREDITO Y SECURITIZACION S',
        swift_code: ''
      },
      {
        name: 'BANCO B.I. CREDITANSTALT SOCIEDAD ANONIM',
        swift_code: ''
      },
      {
        name: 'BANCO BANEX S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO CETELEM ARGENTINA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO CMF S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO COFIDIS S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO COLUMBIA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO COMAFI SOCIEDAD ANONIMA',
        swift_code: ''
      },
      {
        name: 'BANCO COMAFI SOCIEDAD ANONIMA',
        swift_code: ''
      },
      {
        name: 'BANCO CREDICOOP COOPERATIVO LIMITADO',
        swift_code: ''
      },
      {
        name: 'BANCO DE CORRIENTES S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE FORMOSA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE GALICIA Y BUENOS AIRES S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE INVERSION Y COMERCIO EXTERIOR S',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA CIUDAD DE BUENOS AIRES',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA NACION ARGENTINA',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA PROVINCIA DE BUENOS AIRES',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE SAN JUAN S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE SANTA CRUZ S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE VALORES S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DEL CHUBUT S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DEL SOL S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DEL TUCUMAN S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DO BRASIL S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO FINANSUR S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO HIPOTECARIO S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO ITAU BUEN AYRE S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO JULIO SOCIEDAD ANONIMA',
        swift_code: ''
      },
      {
        name: 'BANCO MACRO S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO MARIVA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO MERCURIO S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO MERIDIAN S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO MUNICIPAL DE ROSARIO',
        swift_code: ''
      },
      {
        name: 'BANCO PATAGONIA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO PIANO S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO PRIVADO DE INVERSIONES SOCIEDAD AN',
        swift_code: ''
      },
      {
        name: 'BANCO PROVINCIA DE TIERRA DEL FUEGO',
        swift_code: ''
      },
      {
        name: 'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ',
        swift_code: ''
      },
      {
        name: 'BANCO REGIONAL DE CUYO S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO RIO DE LA PLATA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO SAENZ S.A.',
        swift_code: ''
      },
      {
        name: 'BANCO SUPERVIELLE S.A.',
        swift_code: ''
      },
      {
        name: 'BANK OF AMERICA, NATIONAL ASSOCIATION',
        swift_code: ''
      },
      {
        name: 'BANKBOSTON, NATIONAL ASSOCIATION',
        swift_code: ''
      },
      {
        name: 'BBVA BANCO FRANCES S.A.',
        swift_code: ''
      },
      {
        name: 'BNP PARIBAS',
        swift_code: ''
      },
      {
        name: 'CAJA DE CREDITO COOPERATIVA LA CAPITAL D',
        swift_code: ''
      },
      {
        name: 'CITIBANK N.A.',
        swift_code: ''
      },
      {
        name: 'COMPAÑIA FINANCIERA ARGENTINA S.A.',
        swift_code: ''
      },
      {
        name: 'CREDILOGROS COMPAÑIA FINANCIERA S.A.',
        swift_code: ''
      },
      {
        name: 'DAIMLERCHRYSLER COMPAÑIA FINANCIERA S.A.',
        swift_code: ''
      },
      {
        name: 'DEUTSCHE BANK S.A.',
        swift_code: ''
      },
      {
        name: 'FIAT CREDITO COMPAÑIA FINANCIERA S.A.',
        swift_code: ''
      },
      {
        name: 'FORD CREDIT COMPAÑIA FINANCIERA S.A.',
        swift_code: ''
      },
      {
        name: 'GE COMPAÑIA FINANCIERA S.A.',
        swift_code: ''
      },
      {
        name: 'HSBC BANK ARGENTINA S.A.',
        swift_code: ''
      },
      {
        name: 'ING BANK N.V.',
        swift_code: ''
      },
      {
        name: 'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.',
        swift_code: ''
      },
      {
        name: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO',
        swift_code: ''
      },
      {
        name: 'MASVENTAS S.A. COMPAÑIA FINANCIERA',
        swift_code: ''
      },
      {
        name: 'MBA BANCO DE INVERSIONES S. A.',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO BISEL S.A.',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO DE ENTRE RÍOS S.A.',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO DE LA RIOJA SOCIEDAD ANONIMA',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO DEL CHACO S. A.',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO INDUSTRIAL DE AZUL S.A.',
        swift_code: ''
      },
      {
        name: 'NUEVO BANCO SUQUÍA S.A.',
        swift_code: ''
      },
      {
        name: 'RCI BANQUE',
        swift_code: ''
      },
      {
        name: 'STANDARD BANK ARGENTINA S.A.',
        swift_code: ''
      },
      {
        name: 'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA',
        swift_code: ''
      },
      {
        name: 'TUTELAR COMPAÑIA FINANCIERA S.A.',
        swift_code: ''
      }
    ]
  },
  {
    country: 'belize',
    countryId: 'bz',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      { name: 'ATLANTIC BANK LIMITED', swift_code: 'LIBABZBZXXX' },
      { name: 'ATLANTIC BANK LIMITED', swift_code: 'LIBABZBZ0BS' },
      { name: 'BELIZE BANK LIMITED', swift_code: 'BBLZBZBZXXX' },
      { name: 'CAYE INTERNATIONAL BANK LTD.', swift_code: 'CAYEBZBZXXX' },
      { name: 'CENTRAL BANK OF BELIZE', swift_code: 'CEBBBZBZXXX' },
      { name: 'CHOICE BANK LTD', swift_code: 'CHOIBZBZXXX' },
      { name: 'FIRSTCARIBBEAN INTERNATIONAL BANK', swift_code: 'FCIBBZBZXXX' },
      {
        name: 'PROVIDENT BANK AND TRUST OF BELIZE LIMITED',
        swift_code: 'PRVTBZBSXXX'
      },
      { name: 'SCOTIABANK (BELIZE) LTD.', swift_code: 'NOSCBZBSXXX' }
    ]
  },
  {
    country: 'bolivia',
    countryId: 'bo',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'Banco Nacional de Bolivia (BNB)',
        swift_code: ''
      },
      {
        name: 'Banco Unión S.A.',
        swift_code: ''
      },
      {
        name: 'Banco Unión S.A. ',
        swift_code: ''
      },
      {
        name: 'Banco Mercantil Santa Cruz',
        swift_code: ''
      },
      {
        name: 'Banco BISA',
        swift_code: ''
      },
      {
        name: 'Banco de Crédito',
        swift_code: ''
      },
      {
        name: 'Banco Ganadero',
        swift_code: ''
      },
      {
        name: 'Banco Económico de Bolivia',
        swift_code: ''
      },
      {
        name: 'Banco Sol',
        swift_code: ''
      },
      {
        name: 'ASOBAN - Asociación de Bancos Privados de Bolivia',
        swift_code: ''
      },
      {
        name: 'Banco Central de Bolivia (BCB)',
        swift_code: ''
      }
    ]
  },
  {
    country: 'brazil',
    countryId: 'br',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'ANGLO FERROUS BRAZIL S.A.',
        swift_code: 'ANFEBRRJXXX'
      },
      {
        name: 'BANCO ABC BRASIL S.A.',
        swift_code: 'ABCBBRSPXXX'
      },
      {
        name: 'BANCO ALFA DE INVESTIMENTO S.A.',
        swift_code: 'AUFABRSPXXX'
      },
      {
        name: 'BANCO BARCLAYS SA',
        swift_code: 'BCLYBRSPXXX'
      },
      {
        name: 'BANCO BBM S.A.',
        swift_code: 'BBINBRRJXXX'
      },
      {
        name: 'BANCO BMF DE SERVICOS DE LIQ.E DE CUSTODIA S.A.',
        swift_code: 'BBMFBRSPXXX'
      },
      {
        name: 'BANCO BNP PARIBAS BRASIL S/A',
        swift_code: 'BNPABRSPXXX'
      },
      {
        name: 'BANCO BRADESCO S.A.',
        swift_code: 'BBDEBRSPXXX'
      },
      {
        name: 'BANCO BRASCAN S.A.',
        swift_code: 'BRCABRRJXXX'
      },
      {
        name: 'BANCO BRJ S.A.',
        swift_code: 'BRJBBRRJXXX'
      },
      {
        name: 'BANCO BVA SA',
        swift_code: 'BVABBRRJXXX'
      },
      {
        name: 'BANCO CAIXA GERAL-BRASIL S/A',
        swift_code: 'CGDIBRSPXXX'
      },
      {
        name: 'BANCO CENTRAL DO BRASIL',
        swift_code: 'BCBRBRDFXXX'
      },
      {
        name: 'BANCO CITIBANK SA',
        swift_code: 'CITIBRBRXXX'
      },
      {
        name: 'BANCO CRUZEIRO DO SUL S.A.',
        swift_code: 'BCSLBRSPXXX'
      },
      {
        name: 'BANCO DA AMAZONIA S.A.',
        swift_code: 'AMABBRABXXX'
      },
      {
        name: 'BANCO DAYCOVAL S/A',
        swift_code: 'DAYCBRSPXXX'
      },
      {
        name: 'BANCO DE INVESTIMENTOS CREDIT SUISSE (BRASIL) S.A.',
        swift_code: 'BIGABRRJXXX'
      },
      {
        name: 'BANCO DE LA NACION ARGENTINA',
        swift_code: 'NACNBRSPXXX'
      },
      {
        name: 'BANCO DE LA PROVINCIA DE BUENOS AIRES',
        swift_code: 'PRBABRSPXXX'
      },
      {
        name: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY',
        swift_code: 'BROUBRSPXXX'
      },
      {
        name: 'BANCO DE TOKYO-MITSUBISHI UFJ BRASIL S/A',
        swift_code: 'BOTKBRSXXXX'
      },
      {
        name: 'BANCO DO BRASIL S.A.',
        swift_code: 'BRASBRRJXXX'
      },
      {
        name: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S/A',
        swift_code: 'BRGSBRRSXXX'
      },
      {
        name: 'BANCO DO NORDESTE DO BRASIL S/A',
        swift_code: 'BNBRBRCFXXX'
      },
      {
        name: 'BANCO FATOR SA',
        swift_code: 'BFATBRSPXXX'
      },
      {
        name: 'BANCO FIBRA S.A.',
        swift_code: 'FIBIBRSPXXX'
      },
      {
        name: 'BANCO INDUSTRIAL DO BRASIL S/A',
        swift_code: 'BIBRBRSPXXX'
      },
      {
        name: 'BANCO INDUSTRIAL E COMERCIAL S.A.',
        swift_code: 'BICBBRSPXXX'
      },
      {
        name: 'BANCO INDUSVAL S/A',
        swift_code: 'IDVLBRSPXXX'
      },
      {
        name: 'BANCO INTERCAP SA',
        swift_code: 'ICAPBRSPXXX'
      },
      {
        name: 'BANCO ITAU BBA S.A.',
        swift_code: 'CBBABRSPXXX'
      },
      {
        name: 'BANCO ITAU S/A',
        swift_code: 'ITAUBRSPXXX'
      },
      {
        name: 'BANCO JP MORGAN S.A.',
        swift_code: 'CHASBRSPXXX'
      },
      {
        name: 'BANCO KDB DO BRASIL S.A.',
        swift_code: 'KODBBRSPXXX'
      },
      {
        name: 'BANCO KEB DO BRASIL S.A.',
        swift_code: 'KOEXBRSPXXX'
      },
      {
        name: 'BANCO MERCANTIL DO BRASIL S.A.',
        swift_code: 'BMBRBRRBXXX'
      },
      {
        name: 'BANCO MERRILL LYNCH DE INVESTIMENTOS S.A.',
        swift_code: 'MLBOBRSPXXX'
      },
      {
        name: 'BANCO MODAL S.A.',
        swift_code: 'MODABRRJXXX'
      },
      {
        name: 'BANCO MORGAN STANLEY DEAN WITTER S.A.',
        swift_code: 'MSDWBRSPXXX'
      },
      {
        name: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL – BNDES',
        swift_code: 'NACDBRRJXXX'
      },
      {
        name: 'BANCO NOSSA CAIXA S/A',
        swift_code: 'NCXXBRSPXXX'
      },
      {
        name: 'BANCO PAULISTA S.A.',
        swift_code: 'PAULBRSPXXX'
      },
      {
        name: 'BANCO PINE S.A.',
        swift_code: 'PBBRBRSPXXX'
      },
      {
        name: 'BANCO RABOBANK INTERNATIONAL BRASIL S/A',
        swift_code: 'RABOBRSPXXX'
      },
      {
        name: 'BANCO RENDIMENTO S/A',
        swift_code: 'RENDBRSPXXX'
      },
      {
        name: 'BANCO RURAL S.A.',
        swift_code: 'RUBABRRBXXX'
      },
      {
        name: 'BANCO SAFRA S.A.',
        swift_code: 'SAFRBRSPXXX'
      },
      {
        name: 'BANCO SANTANDER (BRASIL)',
        swift_code: 'BSCHBRSPXXX'
      },
      {
        name: 'BANCO SANTANDER (BRASIL) S.A.',
        swift_code: 'ABNABRSPXXX'
      },
      {
        name: 'BANCO SCHAHIN S/A',
        swift_code: 'SCHABRSPXXX'
      },
      {
        name: 'BANCO SOCIETE GENERALE BRASIL S/A',
        swift_code: 'BSOGBRSPXXX'
      },
      {
        name: 'BANCO SOFISA S.A.',
        swift_code: 'BSBSBRSPXXX'
      },
      {
        name: 'BANCO STANDARD DE INVESTIMENTOS S.A.',
        swift_code: 'SBLLBRSPXXX'
      },
      {
        name: 'BANCO SUMITOMO MITSUI BRASILEIRO S.A.',
        swift_code: 'SUBRBRSPXXX'
      },
      {
        name: 'BANCO VOTORANTIM S.A.',
        swift_code: 'BAVOBRSPXXX'
      },
      {
        name: 'BANCO WESTLB DO BRASIL S.A.',
        swift_code: 'WELABRSPXXX'
      },
      {
        name: 'BANIF-BANCO INTERNACIONAL DO FUNCHAL (BRASIL), SA',
        swift_code: 'BNIFBRRJXXX'
      },
      {
        name: 'BANK OF CHINA BRASIL S.A.',
        swift_code: 'BKCHBRSPXXX'
      },
      {
        name: 'BDMG-BANCO DE DESENVOLVIMENTO DE MINAS GERAIS',
        swift_code: 'BDMGBRRBXXX'
      },
      {
        name: 'BMF – CAMARA DE DERIVATIVOS',
        swift_code: 'XBMFBRBRXXX'
      },
      {
        name: 'BNY MELLON SERVICOS FINANCEIROS DTVM SA',
        swift_code: 'BNYMBRRJXXX'
      },
      {
        name: 'BOLSA DE MERCADORIAS E FUTUROS – BM E F',
        swift_code: 'XBMFBRSPXXX'
      },
      {
        name: 'BPN BRASIL BANCO MULTIPLO S.A.',
        swift_code: 'BPBMBRSPXXX'
      },
      {
        name: 'BRB-BANCO DE BRASILIA S.A.',
        swift_code: 'BRBSBRDFXXX'
      },
      {
        name: 'CAIXA ECONOMICA FEDERAL',
        swift_code: 'CEFXBRSPXXX'
      },
      {
        name: 'CALYON (FORMERLY BANCO CREDIT LYONNAIS BRASIL S/A)',
        swift_code: 'BAGBBRSPXXX'
      },
      {
        name: 'CAMARA INTERBANCARIA DE PAGAMENTOS-CIP',
        swift_code: 'CCIPBRSPXXX'
      },
      {
        name: 'CITIBANK N.A.',
        swift_code: 'CITIBRSPXXX'
      },
      {
        name: 'COMPANHIA BRASILEIRA DE LIQUIDACAO E CUSTODIA',
        swift_code: 'CLCBBRRJXXX'
      },
      {
        name: 'DEUTSCHE BANK S.A. – BANCO ALEMAO',
        swift_code: 'DEUTBRSPXXX'
      },
      {
        name: 'DRESDNER BANK BRASIL S.A.-BANCO MULTIPLO',
        swift_code: 'DBBMBRSPXXX'
      },
      {
        name: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
        swift_code: 'GOLDBRSPXXX'
      },
      {
        name: 'GOLDMAN SACHS DO BRASIL CORRETORA DE VALORES MOBILIARIOS LTDA',
        swift_code: 'GSBCBRSPXXX'
      },
      {
        name: 'HSBC BANK BRASIL S.A. – BANCO MULTIPLO',
        swift_code: 'BCBBBRPRXXX'
      },
      {
        name: 'ICATU DTVM LTDA.',
        swift_code: 'ICATBRRJXXX'
      },
      {
        name: 'ING BANK N.V.',
        swift_code: 'INGBBRSPXXX'
      },
      {
        name: 'MERCEDES-BENZ DO BRASIL LTDA',
        swift_code: 'DABEBRSXXXX'
      },
      {
        name: 'NBC BANK BRASIL SA BANK MULTIPLO',
        swift_code: 'COMEBRRSXXX'
      },
      {
        name: 'STARAUTO COMERCIO DE VEICULOS LTDA.',
        swift_code: 'DACVBRSXXXX'
      },
      {
        name: 'STAREXPORT TRADING S.A.',
        swift_code: 'DATRBRSXXXX'
      },
      {
        name: 'UNIBANCO-UNIAO DE BANCOS BRASILEIROS S/A',
        swift_code: 'UBBRBRSPXXX'
      },
      {
        name: 'VOTORANTIM CORRETORA DE TIT E VAL MOB LTDA',
        swift_code: 'VCTMBRSPXXX'
      }
    ]
  },
  {
    country: 'chile',
    countryId: 'cl',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCO DE CHILE',
        swift_code: ''
      },
      {
        name: 'BANCO INTERNACIONAL',
        swift_code: ''
      },
      {
        name: 'SCOTIABANK CHILE',
        swift_code: ''
      },
      {
        name: 'BANCO DE CREDITO E INVERSIONES',
        swift_code: ''
      },
      {
        name: 'CORPBANCA',
        swift_code: ''
      },
      {
        name: 'BANCO BICE',
        swift_code: ''
      },
      {
        name: 'HSBC BANK (CHILE)',
        swift_code: ''
      },
      {
        name: '',
        swift_code: ''
      },
      {
        name: 'BANCO SANTANDER-CHILE',
        swift_code: ''
      },
      {
        name: 'BANCO ITAÚ CHILE',
        swift_code: ''
      },
      {
        name: 'THE ROYAL BANK OF SCOTLAND (CHILE) (Antes ABN AMRO BANK)',
        swift_code: ''
      },
      {
        name: 'BANCO SECURITY',
        swift_code: ''
      },
      {
        name: 'BANCO FALABELLA',
        swift_code: ''
      },
      {
        name: 'DEUTSCHE BANK (CHILE)',
        swift_code: ''
      },
      {
        name: 'BANCO RIPLEY',
        swift_code: ''
      },
      {
        name: 'RABOBANK CHILE (ex HNS BANCO)',
        swift_code: ''
      },
      {
        name: 'BANCO CONSORCIO (ex BANCO MONEX)',
        swift_code: ''
      },
      {
        name: 'BANCO PENTA',
        swift_code: ''
      },
      {
        name: 'BANCO PARIS',
        swift_code: ''
      },
      {
        name: 'BANCO PENTA',
        swift_code: ''
      },
      {
        name: 'BANCO BILBAO VIZCAY A ARGENTARIA, CHILE (BBVA)',
        swift_code: ''
      },
      {
        name: 'BANCO DO BRASIL S.A.',
        swift_code: ''
      },
      {
        name: 'JP MORGAN CHASE BANK, N.A.',
        swift_code: ''
      },
      {
        name: 'BANCO DE LA NACION ARGENTINA',
        swift_code: ''
      },
      {
        name: 'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD',
        swift_code: ''
      },
      {
        name: 'DnB NOR BANK AS',
        swift_code: ''
      },
      {
        name: 'BCI - SUCURSAL MIAMI',
        swift_code: ''
      },
      {
        name: 'BANCO DEL ESTADO DE CHILE - SUCURSAL NUEVA YORK',
        swift_code: ''
      },
      {
        name: 'CORPBANCA - SUCURSAL NUEVA YORK',
        swift_code: ''
      },
      {
        name: 'BANCO DEL ESTADO DE CHILE',
        swift_code: ''
      },
      {
        name: 'COOCRETAL',
        swift_code: ''
      },
      {
        name: 'COOPEUCH',
        swift_code: ''
      },
      {
        name: 'ORIENCOOP',
        swift_code: ''
      },
      {
        name: 'CAPUAL',
        swift_code: ''
      },
      {
        name: 'DETACOOP',
        swift_code: ''
      }
    ]
  },
  {
    country: 'colombia',
    countryId: 'co',
    nameTaxpayerIdentification: 'RUT',
    banks: [
      {
        name: 'BANCO AGRARIO DE COLOMBIA S.A.',
        swift_code: 'CCAICOBBXXX',
        davivienda_code: 40
      },
      {
        name: 'BANCO AV VILLAS S.A.',
        swift_code: 'BAVICOBBXXX',
        davivienda_code: 52
      },
      {
        name: 'BANCO BILBAO VIZCAYA ARGENTARIA COLOMBIA S.A.',
        swift_code: 'GEROCOBBXXX'
      },
      {
        name: 'BANCO CAJA SOCIAL',
        swift_code: 'CASOCOBBXXX',
        davivienda_code: 32
      },
      {
        name: 'SCOTIABANK COLPATRIA',
        swift_code: 'COLPCOBBXXX',
        davivienda_code: 19
      },
      {
        name: 'BANCO DAVIVIENDA S.A.',
        swift_code: 'CAVDCOBBXXX',
        davivienda_code: 51
      },
      {
        name: 'BANCO DE BOGOTA',
        swift_code: 'BBOGCOBBXXX',
        davivienda_code: 1
      },
      {
        name: 'BANCO DE COMERCIO EXTERIOR S.A.',
        swift_code: 'BCEXCOBBXXX'
      },
      {
        name: 'BANCO DE CREDITO',
        swift_code: 'BCTOCOBBXXX'
      },
      {
        name: 'BANCO DE OCCIDENTE',
        swift_code: 'OCCICOBCXXX',
        davivienda_code: 23
      },
      {
        name: 'BANCO GNB SUDAMERIS',
        swift_code: 'BSUDCOBBXXX',
        davivienda_code: 12
      },
      {
        name: 'BANCO ITAU CORPBANCA COLOMBIA S.A.',
        swift_code: 'BCTOCOBB',
        davivienda_code: 14
      },
      {
        name: 'BANCO POPULAR-COLOMBIA',
        swift_code: 'BPOPCOBBXXX',
        davivienda_code: 2
      },
      {
        name: 'BANCO PROCREDIT COLOMBIA SA',
        swift_code: 'PRCBCOBBXXX',
        davivienda_code: 58
      },
      {
        name: 'BANCO SANTANDER COLOMBIA S.A.',
        swift_code: 'BSCHCOBBXXX',
        davivienda_code: 65
      },
      {
        name: 'BANCOLOMBIA S.A.',
        swift_code: 'COLOCOBMXXX',
        davivienda_code: 7
      },
      {
        name: 'CAMARA DE COMPENSACION DE DIVISAS DE COLOMBIA S.A.',
        swift_code: 'CCDCCOBBXXX'
      },
      {
        name: 'COLTEFINANCIERA S.A.',
        swift_code: 'CLTECOBMXXX',
        davivienda_code: 370
      },
      {
        name: 'CONFIAR Cooperativa Financiera',
        swift_code: '',
        davivienda_code: 292
      },
      {
        name: 'HSBC COLOMBIA , S.A.',
        swift_code: 'BISTCOBBXXX'
      },
      {
        name: 'BANCAMÍA',
        swift_code: ''
      },
      {
        name: 'BANCO COOPERATIVO COOPCENTRAL',
        swift_code: '',
        davivienda_code: 66
      },
      {
        name: 'BANCO CORPBANCA S.A',
        swift_code: '',
        davivienda_code: 6
      },
      {
        name: 'BANCO FALABELLA',
        swift_code: '',
        davivienda_code: 62
      },
      {
        name: 'BANCO PICHINCHA S.A.',
        swift_code: '',
        davivienda_code: 60
      },
      {
        name: 'BANCO PICHINCHA S.A.',
        swift_code: ''
      },
      {
        name: 'BANCOOMEVA S.A.',
        swift_code: '',
        davivienda_code: 61
      },
      {
        name: 'BBVA COLOMBIA S.A.',
        swift_code: '',
        davivienda_code: 13
      },
      {
        name: 'ITAÚ',
        swift_code: '',
        davivienda_code: 14
      },
      {
        name: 'NEQUI',
        swift_code: '',
        davivienda_code: 507
      },
      {
        name: 'NU',
        swift_code: ''
      }
    ]
  },
  {
    country: 'costa rica',
    countryId: 'cr',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      { name: 'COOPESERVIDORES', swift_code: '' },
      { name: 'BANCO BAC SAN JOSE', swift_code: 'BSNJCRSJXXX' },
      { name: 'BANCO BCT S.A.', swift_code: 'CCIOCRSJXXX' },
      { name: 'BANCO CATHAY DE COSTA RICA, S.A.', swift_code: 'KTAYCRSJXXX' },
      { name: 'BANCO CENTRAL DE COSTA RICA', swift_code: 'BCCRCRSJXXX' },
      { name: 'BANCO CITIBANK DE COSTA RICA S.A.', swift_code: 'BCCRCRSJXXX' },
      { name: 'BANCO DE COSTA RICA', swift_code: 'BCRICRSJXXX' },
      { name: 'BANCO GENERAL (COSTA RICA), S.A.', swift_code: 'BAGECRSJXXX' },
      { name: 'BANCO HSBC (COSTA RICA), S.A.', swift_code: 'BXBACRSJXXX' },
      { name: 'BANCO IMPROSA, S.A.', swift_code: 'BXBACRSJXXX' },
      { name: 'BANCO LAFISE S.A.', swift_code: 'BIMPCRSJXXX' },
      { name: 'BANCO NACIONAL DE COSTA RICA', swift_code: 'BNCRCRSJXXX' },
      { name: 'BANCO PROMERICA', swift_code: 'PRMKCRSJXXX' },
      { name: 'SCOTIABANK DE COSTA RICA', swift_code: 'NOSCCRSJXXX' }
    ]
  },
  {
    country: 'cuba',
    countryId: 'cu',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      { name: 'BANCO CENTRAL DE CUBA', swift_code: 'BCCUCUHHXXX' },
      { name: 'BANCO DE CREDITO Y COMERCIO', swift_code: 'BDCRCUHHXXX' },
      { name: 'BANCO DE INVERSIONES S.A.', swift_code: 'BICUCUHHXXX' },
      { name: 'BANCO EXTERIOR DE CUBA', swift_code: 'BECUCUHHXXX' },
      {
        name: 'BANCO FINANCIERO INTERNACIONAL, S.A.',
        swift_code: 'BFICCUHHXXX'
      },
      {
        name: 'BANCO INDUSTRIAL DE VENEZUELA- CUBA S.A.',
        swift_code: 'INDSCUHHXXX'
      },
      {
        name: 'BANCO INTERNACIONAL DE COMERCIO S.A.',
        swift_code: 'BIDCCUHHXXX'
      },
      { name: 'BANCO METROPOLITANO S.A.', swift_code: 'BMNBCUHHXXX' },
      { name: 'BANCO NACIONAL DE CUBA', swift_code: 'BNACCUHHXXX' },
      { name: 'BANCO POPULAR DE AHORRO', swift_code: 'BPAHCUHHXXX' }
    ]
  },
  {
    country: 'ecuador',
    countryId: 'ec',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'ASOCIACION MUTUALISTA DE AHORRO Y CREDITO PARA LA VIVIENDA PICHINCHA',
        swift_code: 'MUPIECEQXXX'
      },
      {
        name: 'BANCO AMAZONAS S.A.',
        swift_code: 'BASAECEGXXX'
      },
      {
        name: 'BANCO BOLIVARIANO C.A.',
        swift_code: 'BBOLECEGXXX'
      },
      {
        name: 'BANCO CENTRAL DEL ECUADOR',
        swift_code: 'BCENECEQXXX'
      },
      {
        name: ' BANCO DE GUAYAQUIL',
        swift_code: 'GUAYECEGXXX'
      },
      {
        name: 'BANCO DE LA PRODUCCION S.A. PRODUBANCO',
        swift_code: 'PRODECEQXXX'
      },
      {
        name: 'BANCO DE LOJA',
        swift_code: 'LOJBECELXXX'
      },
      {
        name: 'BANCO DE MACHALA, C.A.',
        swift_code: 'BMACECEGXXX'
      },
      {
        name: 'BANCO DEL AUSTRO S.A.',
        swift_code: 'AUSTECEQXXX'
      },
      {
        name: 'BANCO DEL PACIFICO',
        swift_code: 'PACIECEGXXX'
      },
      {
        name: 'BANCO GENERAL RUMINAHUI S.A.',
        swift_code: 'BGRMECEQXXX'
      },
      {
        name: 'BANCO INTERNACIONAL S.A.',
        swift_code: 'BINTECEQXXX'
      },
      {
        name: 'BANCO NACIONAL DE FOMENTO',
        swift_code: 'BNFEECEQXXX'
      },
      {
        name: 'BANCO PICHINCHA C.A.',
        swift_code: 'PICHECEQXXX'
      },
      {
        name: 'BANCO PROCREDIT S.A.',
        swift_code: 'PRCBECEQXXX'
      },
      {
        name: 'BANCO PROMERICA',
        swift_code: 'MMJAECEQXXX'
      },
      {
        name: 'BANCO SOLIDARIO S.A.',
        swift_code: 'BSGEECEQXXX'
      },
      {
        name: 'COOPERATIVA DE AHORRO Y CREDITO COOPERA LTDA',
        swift_code: 'RCOOECEQXXX'
      },
      {
        name: 'COOPERATIVA DE AHORRO Y CREDITO DESARROLLO DE LOS PUEBLOS CODESARROLLO',
        swift_code: 'CODSECEQXXX'
      },
      {
        name: 'CORPORACION FINANCIERA NACIONAL',
        swift_code: 'CFNAECEQXXX'
      },
      {
        name: 'LLOYDS TSB BANK PLC',
        swift_code: 'LOYDECEQXXX'
      }
    ]
  },
  {
    country: 'el salvador',
    countryId: 'sv',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCO AGRICOLA, S.A.',
        swift_code: 'CAGRSVSSXXX'
      },
      {
        name: 'BANCO CENTRAL DE RESERVA DE EL SALVADOR',
        swift_code: 'CENRSVSSXXX'
      },
      {
        name: 'BANCO CITIBANK DE EL SALVADOR S.A.',
        swift_code: 'BACUSVSSXXX'
      },
      {
        name: 'BANCO DE AMERICA CENTRAL, S.A.',
        swift_code: 'BAMCSVSSXXX'
      },
      {
        name: 'BANCO HIPOTECARIO DE EL SALVADOR S.A.',
        swift_code: 'BHSASVSSXXX'
      },
      {
        name: 'BANCO HSBC SALVADORENO, S.A.',
        swift_code: 'BSALSVSSXXX'
      },
      {
        name: 'BANCO MULTISECTORIAL DE INVERSIONES',
        swift_code: 'BMDISVSSXXX'
      },
      {
        name: 'BANCO PROCREDIT, S.A.',
        swift_code: 'PRCBSVSSXXX'
      },
      {
        name: 'SCOTIABANK EL SALVADOR, S.A.',
        swift_code: 'BCSASVSSXXX'
      }
    ]
  },
  {
    country: 'estados unidos',
    countryId: 'us',
    nameTaxpayerIdentification: 'TAX ID',
    banks: [
      {
        name: 'ABACUS FEDERAL SAVINGS BANK',
        swift_code: 'AFSBUS33'
      },
      {
        name: 'ABN AMRO BANK N.V.',
        swift_code: 'ABNAUS4C'
      },
      {
        name: 'AMERICAN FIRST NATIONAL BANK',
        swift_code: 'AFNBUS4H'
      },
      {
        name: 'BANK OF AMERICA',
        swift_code: 'BOFAUS3M'
      },
      {
        name: 'BANK UNITED',
        swift_code: 'BUFBUS3M'
      },
      {
        name: 'BARCLAYS BANK',
        swift_code: 'BARCUS3M'
      },
      {
        name: 'BNP PARIBAS',
        swift_code: 'BNPAUS3M'
      },
      {
        name: 'CAPITAL ONE',
        swift_code: 'NFBKUS33'
      },
      {
        name: 'CITIBANK',
        swift_code: 'CALCUS6L'
      },
      {
        name: 'HSBC BANK USA',
        swift_code: 'HSBCUS33'
      },
      {
        name: 'JP MORGAN CHASE BANK',
        swift_code: 'CUTCUS33'
      },
      {
        name: 'SILICON VALLEY BANK',
        swift_code: 'SVBKUS6S'
      },
      {
        name: 'U.S. CENTURY BANK',
        swift_code: 'USCEUS3M'
      },
      {
        name: 'WACHOVIA BANK',
        swift_code: 'PNBPUS6L'
      },
      {
        name: 'WELLS FARGO',
        swift_code: 'WFBIUS6S'
      },
      {
        name: 'ZIONS FIRST NATIONAL BANK',
        swift_code: 'ZFNBUS55'
      }
    ]
  },
  {
    country: 'guatemala',
    countryId: 'gt',
    nameTaxpayerIdentification: 'NIT',
    banks: [
      {
        name: 'BANCO AGROMERCANTIL DE GUATEMALA, S.A.',
        swift_code: 'AGROGTGCXXX'
      },
      {
        name: 'BANCO CITIBANK DE GUATEMALA, S.A.',
        swift_code: 'BACUGTGCXXX'
      },
      {
        name: 'BANCO DE GUATEMALA',
        swift_code: 'BAGUGTGBXXX'
      },
      {
        name: 'BANCO G AND T CONTINENTAL, S.A.',
        swift_code: 'GTCOGTGCXXX'
      },
      {
        name: 'BANCO INDUSTRIAL, S.A.',
        swift_code: 'INDLGTGCXXX'
      },
      {
        name: 'BANCO INTERNACIONAL, S.A.',
        swift_code: 'DIBIGTGCXXX'
      },
      {
        name: 'BANCO REFORMADOR, S.A.',
        swift_code: 'CTRCGTGCXXX'
      },
      {
        name: 'BANRURAL S.A.',
        swift_code: 'BRRLGTGCXXX'
      },
      {
        name: 'CITIBANK N.A. GUATEMALA',
        swift_code: 'CITIGTGCXXX'
      },
      {
        name: 'OCCIDENTE INTERNATIONAL CORPORATION',
        swift_code: 'OCICGTGCXXX'
      }
    ]
  },
  {
    country: 'honduras',
    countryId: 'hn',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCO ATLANTIDA',
        swift_code: 'ATTDHNTEXXX'
      },
      {
        name: 'BANCO CENTRAL DE HONDURAS',
        swift_code: 'BCEHHNTEXXX'
      },
      {
        name: 'BANCO CENTROAMERICANO DE INTEGRACION ECONOMICA',
        swift_code: 'BCIEHNTEXXX'
      },
      {
        name: 'BANCO CONTINENTAL, S.A.',
        swift_code: 'CSPSHNTEXXX'
      },
      {
        name: 'BANCO CUSCATLAN DE HONDURAS',
        swift_code: 'BACUHNTEXXX'
      },
      {
        name: 'BANCO DE AMERICA CENTRAL HONDURAS, S.A.(BAC BAMER)',
        swift_code: 'BMILHNTEXXX'
      },
      {
        name: 'BANCO DE OCCIDENTE S.A.',
        swift_code: 'BOCCHNTEXXX'
      },
      {
        name: 'BANCO DEL PAIS,S.A.',
        swift_code: 'PISAHNTEXXX'
      },
      {
        name: 'BANCO FINANCIERA CENTROAMERICANA S.A.',
        swift_code: 'CABFHNTEXXX'
      },
      {
        name: 'BANCO FINANCIERA COMERCIAL HONDURENA S.A. (BANCO FICOHSA)',
        swift_code: 'FCOHHNTEXXX'
      },
      {
        name: 'BANCO LAFISE,S.A.',
        swift_code: 'BCCEHNTEXXX'
      },
      {
        name: 'BANCO PROCREDIT HONDURAS S.A.',
        swift_code: 'PRCBHNTEXXX'
      },
      {
        name: 'HSBC HONDURAS, S.A.',
        swift_code: 'BGAHHNTEXXX'
      }
    ]
  },
  {
    country: 'haití',
    countryId: 'ht',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANK OF NOVA SCOTIA',
        swift_code: 'NOSCHTPPXXX'
      },
      {
        name: 'BANQUE DE LA REPUBLICA DHAITI',
        swift_code: 'BRHAHTPPXXX'
      },
      {
        name: 'BANQUE DE LUNION HAITIENNE',
        swift_code: 'BUHEHTPPXXX'
      },
      {
        name: 'BANQUE DE PROMOTION COMMERCIALE ET INDUSTRIELLE S.A.',
        swift_code: 'BDPCHTP1XXX'
      },
      {
        name: 'BANQUE NATIONALE DE CREDIT (BNC)',
        swift_code: 'BNCHHTPPXXX'
      },
      {
        name: 'BANQUE NATIONALE DE DEVELOPPEMENT AGRICOLE ET INDUSTRIEL',
        swift_code: 'BNDIHT21XXX'
      },
      {
        name: 'BANQUE NATIONALE DE PARIS',
        swift_code: 'BNPAHTP1XXX'
      },
      {
        name: 'CAPITAL BANK S.A.',
        swift_code: 'CPPLHTPPXXX'
      },
      {
        name: 'CITIBANK NA',
        swift_code: 'CITIHTP1XXX'
      },
      {
        name: 'SOCIETE GENERALE HAITIENNE',
        swift_code: 'SOGHHTPPXXX'
      },
      {
        name: 'SOCIETE GENERALE HAITIENNE DE BANQUE S.A.',
        swift_code: 'SOGHHTP1023'
      },
      {
        name: 'UNIBANK S.A.',
        swift_code: 'UBNKHTPPXXX'
      }
    ]
  },
  {
    country: 'jamaica',
    countryId: 'jm',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'Bank of Jamaica',
        swift_code: ''
      },
      {
        name: 'C&WJ Co-operative Credit Union',
        swift_code: ''
      },
      {
        name: 'FirstCaribbean International Bank Ltd.',
        swift_code: ''
      },
      {
        name: 'Jamaica Co-operative Credit Union League Limited',
        swift_code: ''
      },
      {
        name: 'National Commercial Bank',
        swift_code: ''
      },
      {
        name: 'RBTT Bank Jamaica Limited',
        swift_code: ''
      }
    ]
  },
  {
    country: 'mexico',
    countryId: 'mx',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'ACCIONES Y VALORES DE MEXICO, S.A. DE C.V. CASA DE BOLSA',
        swift_code: 'ACVLMXMMXXX'
      },
      {
        name: 'BANCA AFIRME S.A.',
        swift_code: 'AFIRMXMTXXX'
      },
      {
        name: 'BANCO AZTECA, S.A. INSTITUCION DE BANCA MULTIPLE',
        swift_code: 'AZTKMXMMXXX'
      },
      {
        name: 'BANCO CREDIT SUISSE (MEXICO) S.A.',
        swift_code: 'CSFBMXMMXXX'
      },
      {
        name: 'BANCO DE MEXICO',
        swift_code: 'BDEMMXMMXXX'
      },
      {
        name: 'BANCO DEL AHORRO NACIONAL Y SERVICIOS FINANCIEROS S.N.C INSTITUCION DE BANCA DE DESARROLLO',
        swift_code: 'SEFIMXMMXXX'
      },
      {
        name: 'BANCO DEL BAJIO, S.A.',
        swift_code: 'BJIOMXMLXXX'
      },
      {
        name: 'BANCO INBURSA S.A.',
        swift_code: 'INBUMXMMXXX'
      },
      {
        name: 'BANCO INTERACCIONES',
        swift_code: 'INBUMXMMXXX'
      },
      {
        name: 'BANCO JP MORGAN S.A.',
        swift_code: 'CHASMXMXXXX'
      },
      {
        name: 'BANCO MERCANTIL DEL NORTE, S.A.',
        swift_code: 'MENOMXMTXXX'
      },
      {
        name: 'BANCO MONEX, S.A.',
        swift_code: 'MONXMXMMXXX'
      },
      {
        name: 'BANCO NACIONAL DE COMERCIO EXTERIOR, S.N.C.',
        swift_code: 'BNCEMXMMXXX'
      },
      {
        name: 'BANCO NACIONAL DE MEXICO S.A.',
        swift_code: 'BNMXMXMMXXX'
      },
      {
        name: 'BANCO NACIONAL DEL EJERCITO, FUERZA AEREA Y ARMADA, S.N.C.',
        swift_code: 'EJERMXMMXXX'
      },
      {
        name: 'BANCO REGIONAL DE MONTERREY, S.A.',
        swift_code: 'RGIOMXMTXXX'
      },
      {
        name: 'BANCO SANTANDER (MEXICO), S.A., INSTITUCION DE BANCA MULTIPLE, GRUPO FINANCIERO SANTANDER',
        swift_code: 'BMSXMXMMXXX'
      },
      {
        name: 'BANK OF AMERICA, MEXICO S.A.',
        swift_code: 'BOFAMXMXXXX'
      },
      {
        name: 'BANORTE',
        swift_code: 'MENOMXMTXXX'
      },
      {
        name: 'BARCLAYS BANK MEXICO S.A.',
        swift_code: 'BARCMXMMXXX'
      },
      {
        name: 'BBVA BANCOMER, S.A.',
        swift_code: 'BCMRMXMMXXX'
      },
      {
        name: 'CASA DE BOLSA CREDIT SUISSE (MEXICO), S.A. DE C.V.',
        swift_code: 'CSFBMXMXXXX'
      },
      {
        name: 'CONSULTORIA INTERNACIONAL BANCO SA',
        swift_code: ''
      },
      {
        name: 'CIMXMXMMXXX',
        swift_code: ''
      },
      {
        name: 'DAIMLER MEXICO, SA',
        swift_code: 'DCAGMXMMXXX'
      },
      {
        name: 'DEXIA CREDITO LOCAL MEXICO S.A.',
        swift_code: 'DCLMMXMMXXX'
      },
      {
        name: 'HSBC MEXICO, S.A.',
        swift_code: 'BIMEMXMMXXX'
      },
      {
        name: 'ING BANK (MEXICO) SA',
        swift_code: 'INGBMXMMXXX'
      },
      {
        name: 'ING MEXICO S.A. DE C.V.',
        swift_code: 'IBMCMXMMXXX'
      },
      {
        name: 'INTERCAM CASA DE BOLSA S.A.',
        swift_code: 'ICABMXMMXXX'
      },
      {
        name: 'IXE BANCO S.A.',
        swift_code: 'IXEBMXMMXXX'
      },
      {
        name: 'MORGAN STANLEY',
        swift_code: 'MSMCMXMMXXX'
      },
      {
        name: 'NACIONAL FINANCIERA, S.N.C.',
        swift_code: 'NFSAMXMMXXX'
      },
      {
        name: 'S.D. INDEVAL, S.A.',
        swift_code: 'INDEMXMMXXX'
      },
      {
        name: 'SCOTIABANK INVERLAT, S.A.',
        swift_code: 'MBCOMXMMXXX'
      },
      {
        name: 'THE BANK OF NEW YORK MELLON',
        swift_code: 'IRVTMXMXXXX'
      },
      {
        name: 'THE ROYAL BANK OF SCOTLAND MEXICO, S.A.',
        swift_code: 'ABNAMXMMXXX'
      },
      {
        name: 'UBS BANK MEXICO SA INSTITUCION DE BANCA MULTIPLE',
        swift_code: 'UBSWMXMMXXX'
      },
      {
        name: 'UNICA CASA DE CAMBIO SA',
        swift_code: 'UNACMXMMXXX'
      }
    ]
  },
  {
    country: 'nicaragua',
    countryId: 'ni',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCA UNO NICARAGUA',
        swift_code: 'BUNONIMAXXX'
      },
      {
        name: 'BANCO CENTRAL DE NICARAGUA',
        swift_code: 'BCNINIMAXXX'
      },
      {
        name: 'BANCO DE AMERICA CENTRAL',
        swift_code: 'BAMCNIMAXXX'
      },
      {
        name: 'BANCO DE CREDITO CENTROAMERICANO',
        swift_code: 'BCCENIMAXXX'
      },
      {
        name: 'BANCO DE FINANZAS, S.A.(BDF)',
        swift_code: 'BBDFNIMAXXX'
      },
      {
        name: 'BANCO DE LA PRODUCCION S.A.',
        swift_code: 'BAPRNIMAXXX'
      },
      {
        name: 'BANCO PROCREDIT NICARAGUA',
        swift_code: 'PRCBNIMAXXX'
      },
      {
        name: 'BANISTMO NICARAGUA S.A.',
        swift_code: 'BISTNIMAXXX'
      }
    ]
  },
  {
    country: 'panamá',
    countryId: 'pa',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'AUSTROBANK (OVERSEAS) PANAMA S.A.',
        swift_code: 'AUBAPAPAXXX'
      },
      {
        name: 'BANCAFE (BANCO CAFETERO)',
        swift_code: 'CAFEPAPAXXX'
      },
      {
        name: 'BANCO ALIADO, S.A.',
        swift_code: 'BAALPAPAXXX'
      },
      {
        name: 'BANCO BILBAO VIZCAYA ARGENTARIA (PANAMA), S.A.',
        swift_code: 'BBVAPAPAXXX'
      },
      {
        name: 'BANCO CUSCATLAN DE PANAMA, S.A.',
        swift_code: 'BACUPAPAXXX'
      },
      {
        name: 'BANCO DE BOGOTA, S.A.',
        swift_code: 'BBOGPAPAXXX'
      },
      {
        name: 'BANCO DE GUAYAQUIL (PANAMA) S.A.',
        swift_code: 'GUAYPAPAXXX'
      },
      {
        name: 'BANCO DE LA NACION ARGENTINA',
        swift_code: 'NACNPAPAXXX'
      },
      {
        name: 'BANCO DE OCCIDENTE (PANAMA), S.A.',
        swift_code: 'OCCIPAPAXXX'
      },
      {
        name: 'BANCO DEL PACIFICO (PANAMA) S.A.',
        swift_code: 'PACIPAPXXXX'
      },
      {
        name: 'BANCO GENERAL S A',
        swift_code: 'BAGEPAPAXXX'
      },
      {
        name: 'BANCO INTERNACIONAL DE COSTA RICA S.A.',
        swift_code: 'COSRPAPAXXX'
      },
      {
        name: 'BANCO LAFISE PANAMA',
        swift_code: 'BCCEPAPAXXX'
      },
      {
        name: 'BANCO LATINOAMERICANO DE COMERCIO EXTERIOR, S.A. (BLADEX)',
        swift_code: 'BLAEPAPAXXX'
      },
      {
        name: 'BANCO NACIONAL DE PANAMA',
        swift_code: 'NAPAPAPAXXX'
      },
      {
        name: 'BANCO PICHINCHA PANAMA',
        swift_code: 'PICHPAPAXXX'
      },
      {
        name: 'BANCO TRASATLANTICO, S.A.',
        swift_code: 'BTACPAPAXXX'
      },
      {
        name: 'BANCO UNION SUCURSAL PANAMA',
        swift_code: 'UNIOPAPAXXX'
      },
      {
        name: 'BANCO UNIVERSAL S.A.',
        swift_code: 'BUNVPAPAXXX'
      },
      {
        name: 'BANCOLOMBIA PANAMA S.A.',
        swift_code: 'COLOPAPAXXX'
      },
      {
        name: 'BANESCO INTERNATIONAL BANK. INC.',
        swift_code: 'BANSPAPAXXX'
      },
      {
        name: 'BANK LEUMI LE ISRAEL B.M.',
        swift_code: 'LUMIPAPAXXX'
      },
      {
        name: 'BANK OF CHINA, PANAMA BRANCH',
        swift_code: 'LUMIPAPAXXX'
      },
      {
        name: 'BANK OF NOVA SCOTIA',
        swift_code: 'NOSCPAPAXXX'
      },
      {
        name: 'BCT BANK INTERNATIONAL, S.A.',
        swift_code: 'BCTBPAPAXXX'
      },
      {
        name: 'BLUBANK LTD.',
        swift_code: 'IBNKPAPAXXX'
      },
      {
        name: 'BNP PARIBAS WEALTH MANAGEMENT,SUCURSAL PANAMA',
        swift_code: 'BPPBPAPAXXX'
      },
      {
        name: 'BNP PARIBAS-PANAMA BRANCH',
        swift_code: 'BNPAPAPAXXX'
      },
      {
        name: 'CAPITAL BANK INC.',
        swift_code: 'CAPBPAPAXXX'
      },
      {
        name: 'CITIBANK N.A. SUCURSAL PANAMA',
        swift_code: 'CITIPAPAXXX'
      },
      {
        name: 'CREDICORP BANK S.A',
        swift_code: 'CRLDPAPAXXX'
      },
      {
        name: 'ES BANK (PANAMA) S.A.',
        swift_code: 'ESBPPAPAXXX'
      },
      {
        name: 'FIRST CENTRAL INTERNATIONAL BANK INC.',
        swift_code: 'FCINPAPAXXX'
      },
      {
        name: 'FPB INTERNATIONAL BANK INC.',
        swift_code: 'FCINPAPAXXX'
      },
      {
        name: 'GLOBAL BANK CORPORATION',
        swift_code: 'GLBLPAPAXXX'
      },
      {
        name: 'GNB SUDAMERIS BANK S.A.',
        swift_code: 'GNBKPAPAXXX'
      },
      {
        name: 'HELM BANK (PANAMA)',
        swift_code: 'BCTOPAPAXXX'
      },
      {
        name: 'HSBC BANK USA PANAMA',
        swift_code: 'MIDLPAPAXXX'
      },
      {
        name: 'INTERNATIONAL UNION BANK S.A.',
        swift_code: 'IUBKPAPAXXX'
      },
      {
        name: 'KOREA EXCHANGE BANK',
        swift_code: 'KOEXPAPAXXX'
      },
      {
        name: 'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD.',
        swift_code: 'ICBCPAPAXXX'
      },
      {
        name: 'MERCANTIL BANK (PANAMA) S.A.',
        swift_code: 'ICBCPAPAXXX'
      },
      {
        name: 'METROBANK S.A.',
        swift_code: 'METOPAPAXXX'
      },
      {
        name: 'MMG BANK CORPORATION',
        swift_code: 'MMGBPAPAXXX'
      },
      {
        name: 'MULTIBANK INC.',
        swift_code: 'MCTBPAPAXXX'
      },
      {
        name: 'POPULAR BANK LTD. INC.',
        swift_code: 'BPDOPAPAXXX'
      },
      {
        name: 'PRODUBANK PANAMA S.A.',
        swift_code: 'PRODPAPAXXX'
      },
      {
        name: 'STANFORD BANK (PANAMA) S.A.',
        swift_code: 'SIBPPAPAXXX'
      },
      {
        name: 'TOWERBANK INTERNATIONAL, INC.',
        swift_code: 'TOWEPAPAXXX'
      }
    ]
  },
  {
    country: 'paraguay',
    countryId: 'py',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCO ABN AMRO PARAGUAY S.A.',
        swift_code: 'ABNAPYPXXXX'
      },
      {
        name: 'BANCO AMAMBAY S.A.',
        swift_code: 'AMAMPYPAXXX'
      },
      {
        name: 'BANCO BILBAO VIZCAYA ARGENTARIA PARAGUAY S.A.',
        swift_code: 'BBVAPYPAXXX'
      },
      {
        name: 'BANCO CENTRAL DEL PARAGUAY',
        swift_code: 'BCPAPYPXXXX'
      },
      {
        name: 'BANCO CONTINENTAL SAECA',
        swift_code: 'BCNAPYPAXXX'
      },
      {
        name: 'BANCO DE LA NACION ARGENTINA',
        swift_code: 'NACNPYPAXXX'
      },
      {
        name: 'BANCO DO BRASIL S.A.',
        swift_code: 'BRASPYPXXXX'
      },
      {
        name: 'BANCO INTEGRACION S.A.',
        swift_code: 'BNITPYPAXXX'
      },
      {
        name: 'BANCO NACIONAL DE FOMENTO',
        swift_code: 'BNFAPYPAXXX'
      },
      {
        name: 'BANCO REGIONAL S.A.',
        swift_code: 'BNFAPYPAXXX'
      },
      {
        name: 'FONPLATA – FONDO FINANCIERO PARA EL DESAROLLO DE LA CUENCA DEL PLATA',
        swift_code: 'FOPLPYPAXXX'
      },
      {
        name: 'HSBC BANK PARAGUAY S.A.',
        swift_code: 'HSBCPYPXXXX'
      },
      {
        name: 'INTERBANCO S/A',
        swift_code: 'UBBRPYPXXXX'
      },
      {
        name: 'SUDAMERIS BANK S.A.E.C.A.',
        swift_code: 'BSUDPYPXXXX'
      },
      {
        name: 'VISION BANCO S.A.E.C.A.',
        swift_code: 'VISCPYPAXXX'
      }
    ]
  },
  {
    country: 'perú',
    countryId: 'pe',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCO CENTRAL DE RESERVA DEL PERU',
        swift_code: 'CRPEPEPLXXX'
      },
      {
        name: 'BANCO CONTINENTAL',
        swift_code: 'BCONPEPLXXX'
      },
      {
        name: 'BANCO DE COMERCIO',
        swift_code: 'BDCMPEPLXXX'
      },
      {
        name: 'BANCO DE CREDITO DEL PERU',
        swift_code: 'BCPLPEPLXXX'
      },
      {
        name: 'BANCO DE LA NACION',
        swift_code: 'BANCPEPLXXX'
      },
      {
        name: 'BANCO DEL TRABAJO',
        swift_code: 'BDTRPEPLXXX'
      },
      {
        name: 'BANCO FINANCIERO DEL PERU',
        swift_code: 'FINAPEPLXXX'
      },
      {
        name: 'BANCO INTERAMERICANO DE FINANZAS',
        swift_code: 'BIFSPEPLXXX'
      },
      {
        name: 'BANCO INTERNACIONAL DEL PERU (INTERBANK)',
        swift_code: 'BINPPEPLXXX'
      },
      {
        name: 'BANCO SANTANDER PERU S.A.',
        swift_code: 'BSAPPEPLXXX'
      },
      {
        name: 'CETCO S.A.',
        swift_code: 'BELPPEPLXXX'
      },
      {
        name: 'CITIBANK DEL PERU S.A.',
        swift_code: 'CITIPEPLXXX'
      },
      {
        name: 'CORPORACION FINANCIERA DE DESARROLLO S.A. – COFIDE',
        swift_code: 'COFDPEPLXXX'
      },
      {
        name: 'DEUTSCHE BANK (PERU) S.A.',
        swift_code: 'DEUTPEPLXXX'
      },
      {
        name: 'HSBC BANK PERU SA',
        swift_code: 'HBPEPEPLXXX'
      },
      {
        name: 'MIBANCO, BANCO DE LA MICRO EMPRESA S.A.',
        swift_code: 'MIBAPEPLXXX'
      },
      {
        name: 'SCOTIABANK PERU S.A.A.',
        swift_code: 'BSUDPEPLXXX'
      }
    ]
  },
  {
    country: 'uruguay',
    countryId: 'uy',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'BANCO BILBAO VIZCAYA ARGENTARIA URUGUAY S.A.',
        swift_code: 'BFUYUYMMXXX'
      },
      {
        name: 'BANCO CENTRAL DEL URUGUAY',
        swift_code: 'CBCUUYMMXXX'
      },
      {
        name: 'BANCO DE LA NACION ARGENTINA',
        swift_code: 'NACNUYMMXXX'
      },
      {
        name: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY',
        swift_code: 'BROUUYMMXXX'
      },
      {
        name: 'BANCO GALICIA URUGUAY S.A.',
        swift_code: 'GABAUYMMXXX'
      },
      {
        name: 'BANCO ITAU',
        swift_code: 'ITAUUYMMXXX'
      },
      {
        name: 'BANCO PATAGONIA (URUGUAY) S.A.I.F.E.',
        swift_code: 'BPATUYMMXXX'
      },
      {
        name: 'BANCO SANTANDER',
        swift_code: 'BSCHUYMMXXX'
      },
      {
        name: 'BANCO SANTANDER MONTEVIDEO (EX ABN AMRO BANK)',
        swift_code: 'ABNAUYMMXXX'
      },
      {
        name: 'BANCO SURINVEST S.A.',
        swift_code: 'SURIUYMMXXX'
      },
      {
        name: 'BANDES URUGUAY S.A.',
        swift_code: 'CFACUYMMXXX'
      },
      {
        name: 'BAPRO URUGUAY',
        swift_code: 'PRBAUYMMXXX'
      },
      {
        name: 'CITIBANK N.A. URUGUAY',
        swift_code: 'CITIUYMMXXX'
      },
      {
        name: 'CREDIT URUGUAY BANCO S.A.',
        swift_code: 'AGRIUYMMXXX'
      },
      {
        name: 'DISCOUNT BANK (LATIN AMERICA)',
        swift_code: 'DBLAUYMMXXX'
      },
      {
        name: 'EXPRINTER (URUGUAY) S.A.',
        swift_code: 'ECBUUYMMXXX'
      },
      {
        name: 'APOALIM LATIN AMERICA S.A.',
        swift_code: 'POALUYMMXXX'
      },
      {
        name: 'HSBC BANK (URUGUAY) S.A.',
        swift_code: 'BLICUYMMXXX'
      },
      {
        name: 'LEUMI (LATIN AMERICA) S.A.',
        swift_code: 'LUMIUYMMXXX'
      },
      {
        name: 'LLOYDS TSB BANK PLC',
        swift_code: 'LOYDUYMMXXX'
      },
      {
        name: 'NUEVO BANCO COMERCIAL S.A.',
        swift_code: 'COMEUYMMXXX'
      }
    ]
  },
  {
    country: 'venezuela',
    countryId: 've',
    nameTaxpayerIdentification: 'Documento de identificación tributaria',
    banks: [
      {
        name: 'ABN-AMRO BANK',
        swift_code: 'ABNAVECAXXX'
      },
      {
        name: 'BANCO ACTIVO, C.A.',
        swift_code: 'ACTIVECAXXX'
      },
      {
        name: 'BANCO AGRICOLA DE VENEZUELA',
        swift_code: 'BAVEVECAXXX'
      },
      {
        name: 'BANCO CANARIAS DE VENEZUELA C.A',
        swift_code: 'BOCAVECAXXX'
      },
      {
        name: 'BANCO CARONI, C.A.',
        swift_code: 'CAROVECAXXX'
      },
      {
        name: 'BANCO CENTRAL DE VENEZUELA',
        swift_code: 'BCVEVECAXXX'
      },
      {
        name: 'BANCO CONFEDERADO S.A.',
        swift_code: 'CONDVECPXXX'
      },
      {
        name: 'BANCO DE COMERCIO EXTERIOR',
        swift_code: 'BCEVVECAXXX'
      },
      {
        name: 'BANCO CONFEDERADO S.A.',
        swift_code: 'CONDVECPXXX'
      },
      {
        name: 'BANCO DE COMERCIO EXTERIOR',
        swift_code: 'BCEVVECAXXX'
      },
      {
        name: 'BANCO DE CORO C.A.',
        swift_code: 'COROVE2CXXX'
      },
      {
        name: 'BANCO DE DESARROLLO ECONOMICO Y SOCIAL DE VENEZUELA',
        swift_code: 'FIVVVECAXXX'
      },
      {
        name: 'BANCO DE EXPORTACION Y COMERCIO C.A.',
        swift_code: 'BECUVECAXXX'
      },
      {
        name: 'BANCO DE VENEZUELA, S.A.',
        swift_code: 'BSCHVECAXXX'
      },
      {
        name: 'BANCO DEL CARIBE S.A.C.A.',
        swift_code: 'CARBVECAXXX'
      },
      {
        name: 'BANCO DEL TESORO, C.A.',
        swift_code: 'BDTEVECAXXX'
      },
      {
        name: 'BANCO EXTERIOR, C.A.',
        swift_code: 'BEXTVECAXXX'
      },
      {
        name: 'BANCO FEDERAL, C.A.',
        swift_code: 'BAFIVECAXXX'
      },
      {
        name: 'BANCO GUAYANA C.A.',
        swift_code: 'BGUAVECBXXX'
      },
      {
        name: 'BANCO INDUSTRIAL DE VENEZUELA, C.A.',
        swift_code: 'INDSVECAXXX'
      },
      {
        name: 'BANCO INTERNACIONAL DE DESARROLLO',
        swift_code: 'IDUNVECAXXX'
      },
      {
        name: 'BANCO MERCANTIL',
        swift_code: 'BAMRVECAXXX'
      },
      {
        name: 'BANCO NACIONAL DE AHORRO Y PRESTAMO',
        swift_code: 'BNAPVECAXXX'
      },
      {
        name: 'BANCO NACIONAL DE CREDITO',
        swift_code: 'NCRTVECAXXX'
      },
      {
        name: 'BANCO OCCIDENTAL DE DESCUENTO BU C.A.',
        swift_code: 'BODEVE2MXXX'
      },
      {
        name: 'BANCO PLAZA',
        swift_code: 'PLZAVECAXXX'
      },
      {
        name: 'BANCO PROVINCIAL S.A.',
        swift_code: 'BPROVECAXXX'
      },
      {
        name: 'BANCO SOFITASA',
        swift_code: 'SFTAVE22XXX'
      },
      {
        name: 'BANESCO',
        swift_code: 'UNIOVECAXXX'
      },
      {
        name: 'BANNORTE',
        swift_code: 'NMBCVECAXXX'
      },
      {
        name: 'BANPLUS ENTIDAD DE AHORRO Y PRESTAMOS',
        swift_code: 'BEHRVECAXXX'
      },
      {
        name: 'BANPRO',
        swift_code: 'PVVDVECAXXX'
      },
      {
        name: 'BANVALOR BANCO DE INVERSION',
        swift_code: 'BVBIVECAXXX'
      },
      {
        name: 'BFC BANCO FONDO COMUN',
        swift_code: 'REPBVECAXXX'
      },
      {
        name: 'BOLIVAR BANCO',
        swift_code: 'BLVRVECAXXX'
      },
      {
        name: 'C.A. CENTRAL',
        swift_code: 'CETLVECAXXX'
      },
      {
        name: 'CAJA VENEZOLANA DE VALORES',
        swift_code: 'CVVCVECAXXX'
      },
      {
        name: 'CASA PROPRIA, E.A.P., C.A.',
        swift_code: 'PROPVECBXXX'
      },
      {
        name: 'CITIBANK N.A.',
        swift_code: 'CITIVECAXXX'
      },
      {
        name: 'CORP BANCA',
        swift_code: 'CONSVECAXXX'
      },
      {
        name: 'CORPORACION ANDINA DE FOMENTO ',
        swift_code: 'CAFCVECAXXX'
      },
      {
        name: 'DEL SUR BANCO UNIVERSAL',
        swift_code: 'DSURVECPXXX'
      },
      {
        name: 'HELM BANK DE VENEZUELA',
        swift_code: 'BCTOVECAXXX'
      },
      {
        name: 'INVERUNION BANCO COMERCIAL C.A.',
        swift_code: 'EUBNVECAXXX'
      },
      {
        name: 'INVERUNION, S.A.',
        swift_code: 'IUCBVECAXXX'
      },
      {
        name: 'ITALCAMBIO',
        swift_code: 'ITALVECAXXX'
      },
      {
        name: 'MI CASA, ENTIDAD DE AHORRO Y PRESTAMO',
        swift_code: 'MCSAVE22XXX'
      },
      {
        name: 'STANFORD BANK, S.A.',
        swift_code: 'SIBPVECAXXX'
      },
      {
        name: 'TOTAL BANK BANCO COMERCIAL',
        swift_code: 'TTALVECAXXX'
      },
      {
        name: 'VENEZOLANO DE CREDITO, S.A.',
        swift_code: 'VZCRVECAXXX'
      },
      {
        name: 'WeSend',
        swift_code: 'no-code'
      }
    ]
  },
  {
    country: 'españa',
    countryId: 'es',
    nameTaxpayerIdentification: 'Número de identificación fiscal',
    banks: [
      {
        name: 'ALTAE BANCO',
        swift_code: ''
      },
      {
        name: 'ARESBANK',
        swift_code: ''
      },
      {
        name: 'BANCA MARCH S.A',
        swift_code: ''
      },
      {
        name: 'BANCA PERSONAL CREDIT SUISSE ESPAÑA',
        swift_code: ''
      },
      {
        name: 'BANCA PUEYO',
        swift_code: ''
      },
      {
        name: 'BANCO ALCALA S.A',
        swift_code: ''
      },
      {
        name: 'BANCO CAIXA GERAL',
        swift_code: ''
      },
      {
        name: 'BANCO CETELEM',
        swift_code: ''
      },
      {
        name: 'BANCO COMDAL S.A',
        swift_code: ''
      },
      {
        name: 'BANCO DE ANDALUCIA S.A',
        swift_code: ''
      },
      {
        name: 'BANCO DE CASTILLA S.A',
        swift_code: ''
      },
      {
        name: 'BANCO DE ESPAÑA',
        swift_code: ''
      },
      {
        name: 'BANCO DE GALICIA S.A',
        swift_code: ''
      },
      {
        name: 'BANCO DE VALENCIA S.A',
        swift_code: ''
      },
      {
        name: 'BANCO MADRID',
        swift_code: ''
      },
      {
        name: 'BBVA',
        swift_code: ''
      },
      {
        name: 'BARCLAYS BANK',
        swift_code: ''
      },
      {
        name: 'CAIXA CATALUNYA',
        swift_code: ''
      },
      {
        name: 'BSCH',
        swift_code: ''
      },
      {
        name: 'CITIBANK',
        swift_code: ''
      },
      {
        name: 'DEUTSCHE BANK',
        swift_code: ''
      },
      {
        name: 'SANTANDER',
        swift_code: ''
      }
    ]
  }
  // eslint-disable-next-line max-lines
];
