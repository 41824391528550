export interface VakiDonationCertificate {
  key: string;
  organizationName: string;
  organizationNit: string;
  organizationLogo: string;
  accountantName: string;
  accountantCardProfessional: string;
  accountantType: AccountantType;
  accountantSignature: string;
  legalRepresentativeName: string;
  legalRepresentativeDocument: string;
  legalRepresentativeDocumentType: LegalRepresentativeDocumentType;
  legalRepresentativeSignature: string;
  vakiKey: string;
  createdAt: number;
}

type AccountantType = typeof ACCOUNTANT_TYPES[number]['value'];
export const ACCOUNTANT_TYPES = [
  {
    value: 'ACCOUNTANT',
    label: 'Contador',
  },
  {
    value: 'STATUTORY_AUDITOR',
    label: 'Revisor fiscal',
  },
];

type LegalRepresentativeDocumentType =
  typeof DOCUMENT_TYPES_REPRESENTATIVE_LEGAL[number]['value'];
export const DOCUMENT_TYPES_REPRESENTATIVE_LEGAL = [
  {
    label: 'Cédula de ciudadanía',
    value: 'CC',
  },
  {
    label: 'Cédula de extranjería',
    value: 'CE',
  },
];
export const getAccountTypeLabel = (type: AccountantType | undefined) => {
  if (!type) {
    return '';
  }
  const accountantType = ACCOUNTANT_TYPES.find(({ value }) => value === type);
  return accountantType ? accountantType.label : '';
};
