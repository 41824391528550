export const DATARIESGOS_DOCUMENTS_TYPES: DatariesgosDocumentTypes[] = [
  {
    countryName: 'Colombia',
    countryCode: 'COL',
    documentTypes: [
      {
        displayName: 'Cédula de Ciudadanía',
        code: 'cc',
        daviviendaCode: 1
      },
      {
        displayName: 'Carnet Diplomático',
        code: 'cd'
      },
      {
        displayName: 'Cédula de Extranjería',
        code: 'ce',
        daviviendaCode: 2
      },
      {
        displayName: 'Internacional',
        code: 'int'
      },
      {
        displayName: 'NIT',
        code: 'nit',
        daviviendaCode: 3
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Permiso Especial de Permanencia',
        code: 'pep'
      },
      {
        displayName: 'Placa',
        code: 'plc'
      },
      {
        displayName: 'Pasaporte',
        code: 'pa',
        daviviendaCode: 5
      },
      {
        displayName: 'Registro Civil',
        code: 'rc',
        daviviendaCode: 13
      },
      {
        displayName: 'Tarjeta de Identidad',
        code: 'ti',
        daviviendaCode: 4
      }
    ]
  },
  {
    countryName: 'Argentina',
    countryCode: 'ARG',
    documentTypes: [
      {
        displayName: 'CUIT -Código Único de Identificación Tributaria',
        code: 'cuit'
      },
      {
        displayName: 'Documento nacional de identidad',
        code: 'dni'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      }
    ]
  },
  {
    countryName: 'Bolivia',
    countryCode: 'BOL',
    documentTypes: [
      {
        displayName: 'Cédula de identidad',
        code: 'ci'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      }
    ]
  },
  {
    countryName: 'Brasil',
    countryCode: 'BRA',
    documentTypes: [
      {
        displayName: 'CNPJ -Cadastro de Persona Jurídica',
        code: 'cnpj'
      },
      {
        displayName: 'CPF -Cadastro de Persona Física',
        code: 'cpf'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'Carteira de Identidade o Registro Geral',
        code: 'rg'
      }
    ]
  },
  {
    countryName: 'Canadá',
    countryCode: 'CAN',
    documentTypes: [
      {
        displayName: 'NAS -Numéro d´assurance social',
        code: 'nas'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'SIN -Social Insurance Number',
        code: 'sin'
      }
    ]
  },
  {
    countryName: 'Chile',
    countryCode: 'CHL',
    documentTypes: [
      {
        displayName: 'Cédula de identidad',
        code: 'ci'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'Rol Interno',
        code: 'ri'
      },
      {
        displayName: 'RUT-Rol Único Tributario',
        code: 'rut'
      }
    ]
  },
  {
    countryName: 'Costa Rica',
    countryCode: 'CRI',
    documentTypes: [
      {
        displayName: 'Cédula de identidad',
        code: 'ci'
      },
      {
        displayName: 'CPJ - Número de cédula de persona jurídica',
        code: 'cpj'
      },
      {
        displayName: 'Cédula de residencia',
        code: 'cr'
      },
      {
        displayName: 'NITE -Número de Identificación Tributaria Especial',
        code: 'nite'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'Tarjeta de Identidad',
        code: 'tdi'
      }
    ]
  },
  {
    countryName: 'República Donminicana',
    countryCode: 'DOM',
    documentTypes: [
      {
        displayName: 'Cédula de identidad y electoral',
        code: 'cie'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RNC -Registro Nacional del Contribuyente',
        code: 'rnc'
      }
    ]
  },
  {
    countryName: 'Ecuador',
    countryCode: 'ECU',
    documentTypes: [
      {
        displayName: 'Cédula de ciudadanía',
        code: 'cc'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RUC -Registro Único de Contribuyentes',
        code: 'ruc'
      }
    ]
  },
  {
    countryName: 'España',
    countryCode: 'ESP',
    documentTypes: [
      {
        displayName: 'CIF -Código de Identificación Fiscal',
        code: 'cif'
      },
      {
        displayName: 'NIF -Código de Identificación Fiscal para empresas',
        code: 'nif'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'VAT -Value Added Tax',
        code: 'vat'
      }
    ]
  },
  {
    countryName: 'Guatemala',
    countryCode: 'GTM',
    documentTypes: [
      {
        displayName: 'Documento personal de identificación',
        code: 'dpi'
      },
      {
        displayName: 'NIT -Número de Identificación Tributaria',
        code: 'nit'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RTU -Registro Tributario Unificado',
        code: 'rtu'
      }
    ]
  },
  {
    countryName: 'Honduras',
    countryCode: 'HND',
    documentTypes: [
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RTN -Registro Tributario Nacional',
        code: 'rtn'
      },
      {
        displayName: 'Tarjeta de identidad',
        code: 'tdi'
      }
    ]
  },
  {
    countryName: 'México',
    countryCode: 'MEX',
    documentTypes: [
      {
        displayName: 'Acta de Nacimiento',
        code: 'an'
      },
      {
        displayName: 'Credencial de discapacidad',
        code: 'cd'
      },
      {
        displayName: 'Credencial del INAPAM',
        code: 'cinapam'
      },
      {
        displayName: 'Cédula Profesional',
        code: 'cp'
      },
      {
        displayName:
          'Clave única de registro de población y Credencial para Votar o Credencial de Elector.',
        code: 'curp'
      },
      {
        displayName: 'Licencia de conducir',
        code: 'lc'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Placa Vehicular',
        code: 'plc'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RFC -Registro Federal de Contribuyentes',
        code: 'rfc'
      }
    ]
  },
  {
    countryName: 'Nicaragua',
    countryCode: 'NIC',
    documentTypes: [
      {
        displayName: 'Cédula de identidad',
        code: 'ci'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RUC -Registro Único de Contribuyentes',
        code: 'ruc'
      }
    ]
  },
  {
    countryName: 'Panamá',
    countryCode: 'PAN',
    documentTypes: [
      {
        displayName: 'Cédula de identidad personal',
        code: 'cip'
      },
      {
        displayName: 'NIT -Número de Identificación Tributaria',
        code: 'nit'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      }
    ]
  },
  {
    countryName: 'Perú',
    countryCode: 'PER',
    documentTypes: [
      {
        displayName: 'Cédula Diplomatica de Identidad',
        code: 'cdi'
      },
      {
        displayName: 'CEX - Carnet de extranjería',
        code: 'cex'
      },
      {
        displayName: 'Carnet de Identidad',
        code: 'ci'
      },
      {
        displayName: 'Documento nacional de identidad',
        code: 'dni'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'Resgistro Nacional de Proveedores del Estado',
        code: 'rnp'
      },
      {
        displayName: 'RUC -Registro Único de Contribuyentes',
        code: 'ruc'
      }
    ]
  },
  {
    countryName: 'Puerto Rico',
    countryCode: 'PRI',
    documentTypes: [
      {
        displayName: 'Licencia de conducir',
        code: 'lc'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Número de Seguro Social',
        code: 'nss'
      }
    ]
  },
  {
    countryName: 'Paraguay',
    countryCode: 'PRY',
    documentTypes: [
      {
        displayName: 'Cédula de identidad civil',
        code: 'cic'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RUC -Registro Único de Contribuyentes',
        code: 'ruc'
      }
    ]
  },
  {
    countryName: 'El Salvador',
    countryCode: 'SLV',
    documentTypes: [
      {
        displayName: 'Carnet de Minoridad',
        code: 'cm'
      },
      {
        displayName: 'Carnet de Residente',
        code: 'cr'
      },
      {
        displayName: 'Documento único de identidad',
        code: 'dui'
      },
      {
        displayName: 'NIT -Número de Identificación Tributaria',
        code: 'nit'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      }
    ]
  },
  {
    countryName: 'Uruguay',
    countryCode: 'URY',
    documentTypes: [
      {
        displayName: 'Cédula de identidad',
        code: 'ci'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RUT -Registro Único Tributario',
        code: 'rut'
      }
    ]
  },
  {
    countryName: 'Estados Unidos',
    countryCode: 'USA',
    documentTypes: [
      {
        displayName: 'Commercial Driver License',
        code: 'cdl'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Social Security Number',
        code: 'ssn'
      },
      {
        displayName: 'TIN -Taxpayer Identification Number',
        code: 'tin'
      }
    ]
  },
  {
    countryName: 'Venezuela',
    countryCode: 'VEN',
    documentTypes: [
      {
        displayName: 'Cédula de identidad',
        code: 'ci'
      },
      {
        displayName: 'Denominación o Razón Social',
        code: 'nom'
      },
      {
        displayName: 'Pasaporte',
        code: 'pp'
      },
      {
        displayName: 'RIF -Registro de Información Fiscal',
        code: 'rif'
      }
    ]
  }
];
interface daviviendaDownloadPlainFile {
  daviviendaCode?: number;
}
export interface DatariesgosDocumentTypes {
  countryName: string;
  countryCode: string;
  documentTypes: DatariesgosDocumentType[];
}
export interface DatariesgosDocumentType extends daviviendaDownloadPlainFile {
  code: string;
  displayName: string;
}
