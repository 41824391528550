export interface FooterNavLink {
  name: string;
  nameEN: string;
  url: string;
  id: string;
}
export interface SocialNetworkNavLink {
  name: string;
  url: string;
  icon: string;
}
export const FOOTER_COLUMN_1: FooterNavLink[] = [
  {
    name: 'Help Center',
    nameEN: 'Help Center',
    url: 'https://help.vaki.co?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    id: 'help-center'
  },
  {
    name: 'Embajadores',
    nameEN: 'Ambassadors',
    url: 'https://embajadores.vaki.co?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'ambassadors'
  },
  {
    name: 'Blog',
    nameEN: 'Blog',
    url: 'https://blog.vaki.co?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'blog'
  }
];
export const FOOTER_COLUMN_2: FooterNavLink[] = [
  {
    name: 'Sobre Vaki',
    nameEN: 'About Vaki',
    url: 'https://help.vaki.co?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    id: 'about-vaki'
  },
  {
    name: '¿Cómo funciona?',
    nameEN: 'How it works?',
    url: 'https://help.vaki.co/es/collections/9124149-que-es-y-como-funciona-vaki?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    id: 'how-it-works'
  },
  {
    name: 'Términos y Condiciones',
    nameEN: 'Terms and Conditions',
    url: 'https://help.vaki.co/es/collections/9138346-terminos-y-condiciones-politica-de-privacidad?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'terms-and-conditions'
  },
  {
    name: 'Reporte de impacto 2023',
    nameEN: 'Impact report 2023',
    url: 'https://vaki.global/impacto2023',
    id: 'impact-report-2023'
  }
];
export const FOOTER_COLUMN_3: FooterNavLink[] = [
  {
    name: 'Vaki impacto',
    nameEN: 'Vaki impacto',
    url: 'https://vaki.co/es/explorar?brand=impacto?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    id: 'vaki-impact'
  },
  {
    name: 'Vaki social',
    nameEN: 'Vaki social',
    url: 'https://vaki.co/social/?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    id: 'vaki-social'
  },
  {
    name: 'Vaki sponsor',
    nameEN: 'Vaki sponsor',
    url: 'https://vaki.co/sponsors?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    id: 'vaki-sponsor'
  },
  {
    name: 'Vaki starter',
    nameEN: 'Vaki starter',
    url: 'https://vaki.co/starter?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'vaki-starter'
  },
  {
    name: 'Vaki politics',
    nameEN: 'Vaki politics',
    url: 'https://vaki.co/politics?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'vaki-politics'
  },
  {
    name: 'Vaki gifts',
    nameEN: 'Vaki gifts',
    url: 'https://vaki.co/gifts?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'vaki-gifts'
  },
  {
    name: 'Vaki fun',
    nameEN: 'Vaki fun',
    url: 'https://vaki.co/es/explorar?brand=fun?utm_source=vaki.co&utm_medium=footer&utm_campaign=footer_v4',
    id: 'vaki-fun'
  }
];

export const SOCIAL_NETWORKS_URLS: SocialNetworkNavLink[] = [
  {
    name: 'Twitter',
    url: 'https://twitter.com/vakiglobal?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    icon: ''
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/vakiglobal?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    icon: ''
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/vakiglobal?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    icon: ''
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/vakiglobal?utm_source=platform&utm_medium=footer&utm_campaign=v4',
    icon: ''
  }
];
