export const FIFTH_OF_SECOND = 200;
export const DEBOUNCE_TIME_300 = 300;
export const HALF_SECOND = 500;
export const ONE_SECOND = 1000;
export const DEBOUNCE_TIME_1000 = 1000;
export const TWO_SECONDS = 2000;

export const VAKI_PRIMARY_COLOR = '#8CC640';
export const CurrencySymbols = {
  ARS: 'AR$',
  AUD: 'A$',
  BOB: '$b',
  BRB: 'R$',
  BRL: 'R$',
  CAD: 'CA$',
  CLP: 'CL$',
  COP: 'COP$',
  CRC: '₡',
  DOP: '$',
  EUR: '€',
  GBP: '£',
  GTQ: 'Q',
  HNL: '$',
  MXN: 'MX$',
  NIO: 'C$',
  PEN: 'S/',
  PYG: '₲',
  USD: 'US$',
  UYU: '$'
};
export type CurrencySymbol = keyof typeof CurrencySymbols;
export const DEFAULT_IMAGES = {
  GIFTS_BANNER:
    'https://user-images.githubusercontent.com/85957691/124185458-4e294880-dabb-11eb-81ab-366b6c813c9a.jpeg',
  REWARD_PLACEHOLDER: '/assets/images/misc/reward-placeholder.svg',
  BANNER_SPONSOR: 'assets/images/misc/default_banner-vaki_sponsors.jpg'
};
export enum MAX_SIZE {
  '1MB' = 1024000,
  '2MB' = 2048000,
  '10MB' = 10048000
}
