import { InjectionToken } from '@angular/core';

export enum Features {
  VAKI_SITES = 'vaki-sites',
  VAKI_GIFTS = 'vaki-gifts',
  VAKI_SPONSORS = 'vaki-sponsors',
  ALGOLIA_RECOMMEND = 'algolia-recommend' // Only available in production
}
export type Feature = `${Features}`;
export type FeatureConfig = {
  [key in Feature]: boolean;
};

export const APP_ID = new InjectionToken<string>('appVersion');
export const ALGOLIA_API_KEY = new InjectionToken<string>('algoliaApiKey');
export const ALGOLIA_APP_ID = new InjectionToken<string>('algoliaApiKey');
export const ALGOLIA_VAKI_INDEX = new InjectionToken<string>(
  'algoliaVakisIndex'
);
export const API_URL = new InjectionToken<string>('apiUrl');
export const HOST_URL = new InjectionToken<string>('hostUrl');
export const MP_NOTIFICATION_URL = new InjectionToken<string>(
  'mpNotificationUrl'
);
export const MP_API_KEY = new InjectionToken<string>('mpApiKey');
export const STRIPE_KEY = new InjectionToken<string>('stripeKey');
export const STRIPE_KEY_MX = new InjectionToken<string>('stripeKeyMx');
export const FEATURE_FLAGS = new InjectionToken<FeatureConfig>('featureFlags');
