import { ProfileSocialNetwork, User } from '../users';
import { VakiCategory } from './vaki-category';
import { VakiFinanceData } from './vaki-finance-data';
import { VakiInfo } from './vaki-info';
import { VakiAnalytics } from './vaki-analytics';
import { Gift, Reward, StarterReward, SubscriptionPlan } from './rewards';

export interface Vaki {
  category: VakiCategory;
  collection_goals: VakiGoal[];
  contact_info: VakiContactInfo;
  finance_data: VakiFinanceData;
  info: VakiInfo;
  key: string;
  owners: VakiOwnersInfo[];
  noListing: boolean;
}

export interface VakiExtended extends Vaki {
  analytics: VakiAnalytics;
  subscriptions?: SubscriptionPlan[];
  rewards?: Reward[];
  gifts?: Gift[];
  updates?: VakiUpdate[];
}

export enum VakiState {
  BORRADOR = 'Borrador',
  CREADA = 'Creada',
  PUBLICADA = 'Publicada',
  VERIFICADA = 'Verificada',
  EN_VERIFICACION = 'En verificación',
  RECHAZADA = 'Rechazada',
  CONF_APORTES = 'Conf Aportes',
  EN_CONSIGNACION = 'Consignación',
  INFO_REQUERIDA = 'Info Requerida',
  CONSIGNADA = 'Consignada',
  FACTURADA = 'Facturada',
  ARCHIVADA = 'Archivada',
  NO_ACTIVADA = 'No activada',
  FRAUDE = 'Fraude'
}

export const VAKI_STATES_ARRAY: VakiState[] = [];

const isValidState = (value: string): value is keyof typeof VakiState =>
  value in VakiState;

for (const state in VakiState) {
  if (isValidState(state)) {
    VAKI_STATES_ARRAY.push(VakiState[state]);
  }
}

export interface VakiOwnersInfo {
  uid: User['uid'];
  email: User['email'];
}

export interface VakiContactInfo {
  name: string;
  email: string;
  phone: string;
  userPhoto?: string;
  about?: string;
  social_networks?: Array<ProfileSocialNetwork>;
  city?: string;
  state?: string;
}

export interface OwnersInfo {
  uid: string;
  email: string;
}

export interface VakiGoal {
  name: string;
  amount: number;
  description: string;
}

export interface VakiUpdate {
  date: number;
  title: string;
  text: string;
  key?: string;
  onlyActiveSubscriptor?: string;
}

export interface RewardItems {
  // RewardKey => quantity
  [rewardKey: string]: number;
}

export interface RewardPropsSelected {
  name: string;
  category: string;
}

export interface CartReward {
  quantity: number;
  total: number;
  reward: StarterReward;
  vakiCurrency: string;
  vakiKey: string;
}

export interface Conditions {
  terms_accepted: number;
}

export interface Documents {
  endorsement: string;
  inscription: string;
  document: string;
  bank?: string;
  idFront?: string;
  idBack?: string;
  rut?: string;
}

export interface PaymentSignature {
  additionalInfo: {
    address: string;
    city: string;
    country: string;
    economicActivityType: string;
    employeeAddress: string;
    employeeName: string;
    employeePhone: string;
    idExpeditionPlace: string;
    phone: string;
  };
  documentFile: string;
  taxIdFile: string;
  termsAccepted: {
    declaration1: boolean;
    declaration2: boolean;
    declaration3: boolean;
  };
  time: number;
  userId: string;
  paymentKey: string;
}

export interface AdminNote {
  note: string;
  date: number;
  key: string;
  userId: string;
}
export interface VakiHits extends Vaki {
  analytics: VakiAnalytics | undefined;
}
export enum KeyActiveVakis {
  MAIN = 'VAKIS_MAIN',
  ALMOST_FINISHED = 'VAKIS_ALMOST_FINISHED',
  FIRST_IMPULSE = 'VAKIS_FIRST_IMPULSE'
}
export interface SubscriptionSummary {
  countActivesSubscriptions: number;
  countCanceledSubscriptions: number;
  countPendingSubscriptions: number;
  countNewSponsors: number;
  totalCollected: number;
  totalPayments: number;
  currentMonthTotalCollected: number;
  currentMonthTotalPayments: number;
  currentMonthPendingPaymentsReceivable: number;
  currentMonthPendingAmountReceivable: number;
  subscriptionsPlans: {
    name: string;
    value: number;
    activesSponsors: number;
    totalPayments: number;
  }[];
}
