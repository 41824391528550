import { MercadopagoPayment } from '../mercadopago';
import { StripePayment } from './stripe-payment.model';

export interface GatewayPayment {
  gateway: string;
  data: StripePayment | MercadopagoPayment;
}

export enum PaymentGateways {
  STRIPE = 'Stripe',
  STRIPE_MX = 'Stripe México',
  MERCADOPAGO = 'Mercadopago',
  PAYU = 'PayU',
  OFFLINE = 'Offline'
}
