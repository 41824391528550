export enum VakiSites {
  VAKI = 'vaki',
  KITSMILE = 'kitsmile'
}
export type VakiSiteId = `${VakiSites}`;

export const VAKI_SITES_ARRAY: VakiSites[] = [];

const isValidState = (value: string): value is keyof typeof VakiSites =>
  value in VakiSites;

for (const state in VakiSites) {
  if (isValidState(state)) {
    VAKI_SITES_ARRAY.push(VakiSites[state]);
  }
}
