export const DAY_MILIS = 86400000;
export const HOUR_MILLIS = 3600000;
export const MINUTE_MILLIS = 60000;
export const TWENTY_FOUR_DAYS = 24;
export const TWENTY_FOUR_DAYS_MILLIS = TWENTY_FOUR_DAYS * DAY_MILIS;
export const YEAR_DAYS = 365;
export const YEAR_MILLIS = YEAR_DAYS * DAY_MILIS;

const getDay = (days: number) => ({
  start: DAY_MILIS * days,
  end: DAY_MILIS * (days + 1),
});

/* eslint-disable no-magic-numbers */
export const ONE_DAY = getDay(1);
export const SEVEN_DAY = getDay(7);
export const SIXTY_DAYS = getDay(60);
/* eslint-enable no-magic-numbers */
