import { CreditCardToken, PayUIdType } from '../payu';
import { Stripe } from 'stripe';
import { PaymentMethod } from '@stripe/stripe-js';

export interface User {
  birthdate?: number;
  createdAt?: number;
  email: string;
  howYouKnowUs?: string;
  hubspotId?: string;
  lastLoginTime?: number;
  name: string;
  phoneNumber?: string;
  photo?: string;
  rol?: number;
  socialNetworks?: Array<ProfileSocialNetwork>;
  uid: string;
  vakerData?: VakerData;
  verified?: {
    emailVerified: boolean;
    phoneVerified: boolean;
  };
  id: {
    type: string;
    number: string;
    person: string;
  };
}

export interface VakerData {
  firstSupportTime?: number;
  timeToFirstSupport?: number;
  lastSupportTime?: number;
  vakerType?: VakerType;
}

export type VakerType = 'regular' | 'recurrent' | 'super' | 'premium';

export enum VakerCategories {
  REGULAR = 1,
  RECURRENT = 2,
  SUPER = 5,
  PREMIUM = 12
}

export interface UserAuthRecord {
  customClaims: { [key: string]: string };
  disabled: boolean;
  displayName: string;
  email: string;
  emailVerified: boolean;
  metadata: {
    creationTime: string;
    lastSignInTime: string;
    lastRefreshTime: string;
  };
  passwordHash: string;
  passwordSalt: string;
  phoneNumber: string;
  photoURL: string;
  providerData: [
    {
      displayName: string;
      email: string;
      photoURL: string;
      providerId: string;
      uid: string;
    }
  ];
  tenantId: string;
  tokensValidAfterTime: string;
  uid: string;
}

export interface UserDataAggregate {
  createdVakis: number;
  vakisSupported: number;
  totalSupported: number;
  totalSupports: number;
  totalActiveSubscriptions: number;
}

export interface UserAddress {
  whoReceives: string;
  country: string;
  state: string;
  city: string;
  town: string;
  addressLine1: string;
  addressLine2: string;
  phoneNumber: string;
}

export interface ProfileSocialNetwork {
  name: SocialNetworks;
  active: boolean;
  url: string;
}

export enum SocialNetworks {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube'
}

export interface Phone {
  code: string;
  number: string;
}

export interface FirebaseUserRecord {
  uid: string;
  email: string;
  phone: string;
  name: string;
  photo?: string;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
  emailVerified: boolean;
}

export interface UserMercadopagoInfo {
  customer_id: string;
}

export interface UserStripeInfo {
  customer_id?: string;
  mx_customer_id?: string;
}

export interface PayuSubscriptions extends UserSubscriptions {
  creditCardTokenId: string;
  user: {
    email: string;
    name: string;
    dni: string;
    dniType: PayUIdType;
  };
  ipAddress: string;
  cookie: string;
  userAgent: string;
}

export interface UserSubscriptions {
  status: string;
  subscriptionId: string;
  vakiKey: string;
  userId: string;
  planId: string;
  initialTime?: number;
  periodStart?: number;
  periodEnd?: number;
  canceledDate?: number | null;
  attempts?: number;
  lastChargedAttempts?: number | null;
  vakerEmail?: string;
  subscriptionName?: string;
}
export interface CreditCardStripe {
  subscription: Stripe.Subscription;
  source: Stripe.Source;
  paymentMethod: PaymentMethod;
}
export interface CreditCardPayu {
  subscription: PayuSubscriptions;
  source: CreditCardToken;
}
export type DefaultCreditCard = CreditCardStripe | CreditCardPayu;
