export enum SuccessNotifications {
  SUCCESS_UPDATE = 1,
  SUCCESS_CREATE,
  SUCCESS_DELETE,
  SUCCESS_PROFILE_UPDATE,
  SUCCESS_PROFILE_PHOTO_UPDATE,
  SUCCESS_PHONE_VERIFICATION,
  SUCCESS_RESET_PASSWORD_EMAIL,
  SUCCESS_VERIFY_EMAIL,
  SUCCESS_COPY,
  SUCCESS_CREATOR_CONTACT,
  SUCCESS_REPORT,
  SUCCESS_REPORT_EMAIL,
  SUCCESS_NOTIFICATION,
  SUCCESS_DEFAULT_BANK_ACCOUNT,
  SUCCESS_VALIDATION,
  SUCCESS_FILE_UPLOAD,
  SUCCESS_LOGIN,
  SUCCESS_REGISTER,
  SUCCESS_PHONE_CODE_REQUEST,
  SUCCESS_SIGNING_OUT,
  SUCCESS_TICKET,
  SUCCESS_CREATE_PAYMENT_DELIVERY,
  SUCCESS_CHECKOUT,
  SUCCESS_SUBSCRIPTION_CANCEL,
  SUCCESS_TRANSLATION
}
