import { Vaki } from './vaki';
import { BRANDS_IDS } from './vaki-brand';

export const DEFAULT_COMISSION = 6;
export const NEW_VAKI: Vaki = {
  key: '',
  owners: [],
  info: {
    name: '',
    url: '',
    start_date: new Date().getTime(),
    close_date: new Date(
      new Date().setDate(new Date().getDate() + 45)
    ).getTime(),
    summary: '',
    description: '',
    photo: '',
    country: '',
    video: '',
    thank_you_message: ''
  },
  collection_goals: [
    {
      name: 'Primera meta 🐮',
      amount: 0,
      description: 'Primera meta'
    }
  ],
  contact_info: {
    email: '',
    phone: '',
    name: ''
  },
  category: {
    brand: BRANDS_IDS.IMPACTO,
    category: ''
  },
  finance_data: {
    currency: 'COP',
    comision: DEFAULT_COMISSION,
    payment_plan: 'transparent'
  },
  noListing: false
};
