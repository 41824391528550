export enum VakiHttpCallablesFunctions {
  CREATE_STRIPE_SUBSCRIPTION = 'v4stripeCreateSubscription',
  MODIFY_WITHDRAW = 'modifyVakiFee',
  UPDATE_USER = 'updateUser',
  DELETE_USER = 'deleteUser',
  CREATE_VAKI = 'createVaki',
  CONTACT_CREATOR = 'contactCreator',
  SHARE_WITH_FRIENDS = 'sendToFriends',
  NOTIFY_VAKI_UPDATE = 'notifyUpdate',
  RECALCULATE_VAKI_ACCOUNTING = 'vakiRecalculateAccounting',
  GET_MERCADOPAGO_CUSTOMER_CARDS = 'mercadopagoGetCustomerCards',
  CREATE_STRIPE_PAYMENT = 'stripeNewPayment', // before: stripeCreatePayment, before: stripeProcessPayment
  CREATE_STRIPE_BANK_PAYMENT = 'stripeNewBankPayment',
  CREATE_STRIPE_OXXO_PAYMENT = 'stripeNewOxxoPayment',
  CONFIRM_STRIPE_PAYMENT = 'stripeConfirmPayment',
  CANCEL_SUBSCRIPTION_STRIPE = 'cancelUserSubscription',
  GET_DEFAULT_CREDIT_CARD = 'getDefaultCreditCard',
  UPDATE_DEFAULT_CREDIT_CARD = 'updateDefaultCreditCard'
}
