import { AuthErrorCodes } from 'firebase/auth';

export enum ErrorNotifications {
  ERROR_UPDATE,
  ERROR_CREATE,
  ERROR_DELETE,
  ERROR_PROFILE_UPDATE,
  ERROR_PROFILE_PHOTO_UPDATE,
  ERROR_PHONE_VERIFICATION,
  ERROR_SENDING_RESET_PASSWORD_EMAIL,
  ERROR_SENDING_VERIFICATION_CODE,
  ERROR_VERIFY_EMAIL,
  ERROR_LOGIN,
  ERROR_REPORT,
  ERROR_NOTIFICATION,
  ERROR_INVALID_EMAIL,
  ERROR_VALIDATION,
  ERROR_FILE_UPLOAD,
  ERROR_GETTING_VAKI_INFO,
  ERROR_PROCESSING_PSE_PAYMENT,
  ERROR_INVALID_CC,
  ERROR_CREATING_PAYMENT,
  ERROR_REJECTED_PAYMENT,
  ERROR_PHONE_CODE_REQUEST,
  ERROR_SIGNING_OUT,
  ERROR_TICKET,
  ERROR_CREATE_PAYMENT_DELIVERY,
  ERROR_CREATING_VAKI_NO_EMAIL,
  ERROR_MP_PAYMENT_VAKI_USER,
  ERROR_MP_PAYMENT_NO_IP,
  ERROR_MP_PAYMENT_WRONG_EMAIL,
  ERROR_MP_PAYMENT_ERROR,
  PEPSICO_API_SAVE_SUPPORT,
  ERROR_CONVERTING_VALUE,
  ERROR_CHECKOUT_SAVING_PAYMENT,
  ERROR_SUBSCRIPTION_CANCEL,
  ERROR_ADD_REWARD_CART,
  ERROR_TRANSLATION
}

export const getErrorMessageByCode = (code: string) => {
  switch (code) {
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'La contraseña no es válida o el usuario no tiene contraseña.';
    case AuthErrorCodes.EMAIL_EXISTS:
      return 'Otro usuario ya está utilizando el correo electrónico proporcionado. Cada usuario debe tener un correo electrónico único.\n';
    case AuthErrorCodes.TOKEN_EXPIRED:
      return 'El token de ID de Firebase que se proporcionó está vencido.';
    case AuthErrorCodes.INTERNAL_ERROR:
      return `El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud. Si el error persiste, avísanos mediante nuestro chat.`;
    case AuthErrorCodes.INVALID_EMAIL:
      return `El valor que se proporcionó para la propiedad del usuario email no es válido. Debe ser una dirección de correo electrónico valida.`;
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return `El valor que se proporcionó para phoneNumber no es válido. Debe cumplir con el estándar E.164.`;
    case AuthErrorCodes.USER_DELETED:
      return `No existe ningún registro de usuario que corresponda al identificador proporcionado.`;
    default:
      return '';
  }
};
