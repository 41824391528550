export enum EditSection {
  BANK = 'bank',
  BASIC = 'basic',
  BRAND = 'brand',
  CREATOR = 'creator',
  CRETIFICATE = 'certificate',
  GOAL = 'goal',
  HISTORY = 'history',
  REWARDS = 'rewards',
  SUBSCRIPTIONS = 'subscriptions',
  GIFT_BASIC = 'giftBasic',
  GIFTS_IMAGES = 'giftsImages',
  GIFTS_HISTORY = 'giftsHistory',
  GIFTS = 'gifts',
  THANK_YOU = 'thankYou',
  UPDATES = 'updates'
}

export interface Section {
  [key: string]: {
    title: string;
    localizationActive: boolean;
  };
}

export const EditSections: Section = {
  basic: {
    title: 'Editar nombre, url y resumen',
    localizationActive: true
  },
  brand: {
    title: 'Editar logo, imagen de portada',
    localizationActive: true
  },
  history: {
    title: 'Editar historia',
    localizationActive: true
  },
  goal: {
    title: 'Editar meta, fecha de cierre y tags',
    localizationActive: false
  },
  creator: {
    title: 'Editar datos del creador y managers de la Vaki',
    localizationActive: false
  },
  thankYou: {
    title: 'Editar mensaje de agradecimiento',
    localizationActive: true
  },
  rewards: {
    title: 'Editar recompensas',
    localizationActive: false
  },
  gifts: {
    title: 'Editar regalos y mensaje de agradecimiento',
    localizationActive: false
  },
  subscriptions: {
    title: 'Editar planes de suscripción',
    localizationActive: false
  },
  updates: {
    title: 'Subir actualizaciones',
    localizationActive: false
  },
  certificate: {
    title: 'Editar certificado de donación',
    localizationActive: false
  },
  bank: {
    title: 'Editar cuenta bancaria y aportes offline',
    localizationActive: false
  },
  giftBasic: {
    title: 'Editar detalles del evento',
    localizationActive: false
  },
  giftsImages: {
    title: 'Editar imágenes de la Vaki',
    localizationActive: false
  },
  giftsHistory: {
    title: 'Editar la descripción de tu evento',
    localizationActive: true
  }
};

export const videoValidation =
  '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$';
