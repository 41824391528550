export enum GA4CheckoutEvents {
  CHECKOUT_BEGIN = 'begin_checkout', // params: coupon, currency, items y value
  CHECKOUT_PAYMENT_COMPLETE = 'checkout_payment_complete',
  CHECKOUT_COMPLETE = 'purchase', // params: transaction_id, value, currency, tax, shipping, items y coupon
  JOIN_VAKI = 'join_group' // params: group_id
}

export const enum GA4ViewEvents {
  VIEW_VAKI = 'view_vaki',
  VIEW_VAKI_REWARDS = 'view_vaki_rewards',
  VIEW_VAKI_EDIT = 'view_vaki_edit',
  VIEW_VAKI_CHECKLIST = 'view_vaki_checklist',
  VIEW_HOME = 'view_home',
  VIEW_EXPLORE = 'view_explore',
  VIEW_CREATE = 'view_create',
  VIEW_CHECKOUT = 'view_checkout',
  VIEW_THANKS = 'view_thanks',
  VIEW_DASHBOARD = 'view_dashboard',
  VIEW_LOGIN = 'view_login', // TODO: previous counting in 'page_visit_login'
  VIEW_SITE_HOME = 'view_site_home'
}

export enum GA4EditEvents {
  EDIT_VAKI_REWARDS = 'edit_vaki_rewards', // TODO: previous counting in 'edit_add_reward',
  EDIT_VAKI_SUBSCRIPTIONS = 'edit_vaki_subscription', // TODO: previous counting in 'edit_add_subscription',
  EDIT_VAKI_GIFTS = 'edit_vaki_gifts',
  EDIT_VAKI_UPDATES = 'edit_vaki_updates', // TODO: previous counting in 'edit_add_update',
  EDIT_VAKI_DELETE_UPDATE = 'edit_vaki_delete_update', // TODO: previous counting in  'edit_delete_update',
  EDIT_VAKI_HISTORY = 'edit_vaki_history', // TODO: previous counting in 'edit_change_history',
  EDIT_VAKI_TITLE = 'edit_vaki_title', // TODO: previous counting in 'edit_change_title',
  EDIT_VAKI_SUMMARY = 'edit_vaki_summary', // TODO: previous counting in 'edit_change_summary',
  EDIT_VAKI_VIDEO = 'edit_vaki_video', // TODO: previous counting in 'edit_change_video',
  EDIT_VAKI_CLOSE_DATE = 'edit_vaki_close_date', // TODO: previous counting in 'edit_change_close_date',
  EDIT_VAKI_TAGS = 'edit_vaki_tags', // TODO: previous counting in 'edit_change_tags',
  EDIT_VAKI_BANK_ACCOUNTS = 'edit_vaki_bank_account', // TODO: previous counting in 'edit_add_account',
  EDIT_VAKI_MANAGERS = 'edit_vaki_managers', // TODO: previous counting in 'edit_add_manager',
  EDIT_VAKI_THANKYOU = 'edit_vaki_thankyou', // TODO: previous counting in 'edit_thank_you_message',
  EDIT_VAKI_GOAL = 'edit_vaki_goal', // TODO: previous counting in 'edit_checklist_goal' + 'edit_change_goals',
  EDIT_VAKI_IMAGE = 'edit_vaki_image', // TODO: previous counting in'edit_checklist_image' + 'edit_change_image',
  EDIT_VAKI_BANNER = 'edit_vaki_banner',
  // in'edit_checklist_image' + 'edit_change_image',
  EDIT_VAKI_PUBLISH_DATE = 'edit_vaki_publish_date', // TODO: previous counting in 'edit_checklist_date' + 'edit_change_launch_date',
  EDIT_VAKI_CATEGORIES = 'edit_vaki_categories', // TODO: previous counting in 'edit_checklist_categories',
  EDIT_VAKI_URL = 'edit_vaki_url', // TODO: previous counting in edit_checklist_url + 'edit_change_url'
  EDIT_VAKI_OFFLINE_SUPPORTS = 'edit_vaki_offline_supports', // TODO: previous counting in 'report_add_offline_support'
  EDIT_VAKI_REQUEST_WITHDRAW = 'edit_vaki_request_withdraw', // TODO: previous counting in report_add_withdraw
  EDIT_VAKI_DOWNLOAD_REPORT = 'edit_vaki_download_report', // TODO: previous counting in 'report_download_excel'
  EDIT_VAKI_IDENTITY = 'edit_vaki_identity', // TODO: previous counting in 'edit_change_identity',
  EDIT_VAKI_LISTING = 'edit_vaki_listing'
}

export const enum GA4SearchEvents {
  SEARCH = 'search', // params: search_term
  SEARCH_KEYWORD = 'search_keyword', // params: search_term
  SEARCH_CATEGORY = 'search_category', // params: search_term
  SEARCH_COUNTRY = 'search_country' // params: search_term
}

export enum GA4ClickEvents {
  CLICK_OPEN_CHECKLIST = 'click_open_checklist', // TODO: previous counting in 'edit_checklist_opened',
  CLICK_VAKI_REWARD = 'click_vaki_reward',
  CLICK_VAKI_REWARD_ADD_CART = 'click_vaki_reward_add_cart',
  CLICK_CHECKOUT_FROM_CART = 'click_checkout_from_cart',
  CLICK_SEARCH_HOMEPAGE = 'click_search_homepage', // TODO: previous counting in 'search_homepage'
  CLICK_CREATE_HOMEPAGE = 'click_create_homepage', // TODO: previous counting in 'create_homepage'
  CLICK_VIEW_EXPLORE = 'click_view_explore',
  CLICK_VIEW_CREATE = 'click_view_create',
  CLICK_MENU_BUTTON = 'click_menu_button',
  CLICK_FOOTER_BUTTON = 'click_footer_button',
  CLICK_CAROUSEL_VIEW = 'click_carousel_view_more',
  CLICK_HOMEPAGE_ACCADEMY = 'click_homepage_academy',
  CLICK_HOMEPAGE_CAROUSEL_VAKI = 'click_homepage_carousel_vaki',
  CLICK_HOMEPAGE_STORY = 'click_homepage_story',
  CLICK_HOMEPAGE_WHY = 'click_homepage_why', // TODO: previous counting in 'click_homepage_why_vaki',
  CLICK_VAKI_EDIT = 'click_vaki_edit',
  CLICK_VAKI_PUBLISH = 'click_vaki_publish',
  CLICK_VAKI_VIEW_REWARDS = 'click_vaki_view_rewards',
  CLICK_VAKI_REPORT = 'click_vaki_report',
  CLICK_SUPPORT_SAVE_IMAGE = 'click_support_save_image',
  CLICK_SUPPORT_INVITE_FIRENDS = 'click_support_invite_friends',
  CLICK_VAKI_SUPPORT = 'click_vaki_support',
  CLICK_SHARE = 'share', // TODO: previous counting in 'click_card_share' + 'click_vaki_share' + 'click_support_share'
  CLICK_CARD_VIEW_VAKI = 'click_card_view_vaki', // TODO: previous counting in 'click_card_title' + 'click_card_image' + 'click_card_view'
  CLICK_CARD_VIEW_REWARDS = 'click_card_view_rewards', // TODO: previous counting in 'click_card_support'
  CLICK_CARD_EXPAND = 'click_card_expand',
  CLICK_CARD_CONDENSE = 'click_card_condense',
  CLICK_EXPLORE_FILTER = 'click_explore_open_filters',
  CLICK_EXPLORE_CATEGORY = 'click_explore_category',
  CLICK_EXPLORE_BRAND = 'click_explore_brand',
  CLICK_EXPLORE_COUNTRY = 'click_explore_country',
  CLICK_EXPLORE_CLEAR_FILTERS = 'click_explore_clear_filters',
  CLICK_EXPLORE_CLEAR_SEARCH = 'click_explore_clear_search',
  CLICK_DOWNLOAD_COLLECTION_CERTIFICATE = 'click_download_collection_certificate',
  CLICK_ADD_MANAGER = 'click_add_manager',
  CLICK_REMOVE_MANAGER = 'click_remove_manager',
  CLICK_DOWNLOAD_REPORT_PAYMENTS = 'click_download_report_payments',
  CLICK_LOGIN_MAGIC_LINK = 'click_login_magic_link',
  CLICK_LOGIN_PASSWORD = 'click_login_password',
  CLICK_LOGIN_RESET_PASSWORD = 'click_login_reset_password',
  CLICK_IFRAME_COPY_CODE = 'click_iframe_copy_code',

  // Clicks in checkout events used here for directive use proupouses
  CHECKOUT_PAYMENT_SIGNATURE_COMPLETE = 'checkout_payment_signature_complete',
  CHECKOUT_PAYMENT_METHOD_SELECTED = 'checkout_payment_method_selected', // params: coupon, currency, items, payment_type y value also count in 'add_payment_info'
  CHECKOUT_TIP_CHANGE = 'checkout_tip_change',
  CHECKOUT_PAYMENT_TERMS_ACCEPTED = 'checkout_payment_terms_accepted'
}

export const enum GA4DashboardEvents {
  DASHBOARD_VAKI_DOWNLOAD_REPORT = 'dashboard_download_report', // TODO: previous counting in 'report_download_excel'
  DASHBOARD_VAKI_REQUEST_WITHDRAW = 'dashboard_vaki_request_withdraw', // TODO: previous counting in report_add_withdraw
  DASHBOARD_SAVE_BANK_ACCOUNT = 'dashboard_save_bank_account',
  DASHBOARD_DEFAULT_BANK_ACCOUNT = 'dashboard_default_bank_account',
  DASHBOARD_VERIFY_BANK_ACCOUNT = 'dashboard_verify_bank_account'
}

export const enum GA4CreateEvents {
  CREATE_VAKI_STEP1 = 'create_vaki_step1',
  CREATE_VAKI_SELECT_BRAND = 'create_vaki_select_brand',
  CREATE_VAKI_SET_TITLE = 'create_vaki_set_title',
  CREATE_VAKI_SET_CATEGORY = 'create_vaki_set_category',
  CREATE_VAKI_SET_SUMMARY = 'create_vaki_set_summary',
  CREATE_VAKI_SET_DOCUMENTS = 'create_vaki_set_documents',
  CREATE_VAKI_SET_LAUNCH_DATE = 'create_vaki_set_launch_date',
  CREATE_VAKI_SET_EVENT_DATE = 'create_vaki_set_event_date',
  CREATE_VAKI_SET_CLOSE_DATE = 'create_vaki_set_close_date',
  CREATE_VAKI_STEP2 = 'create_vaki_step2',
  CREATE_VAKI_SET_COUNTRY = 'create_vaki_set_country',
  CREATE_VAKI_SET_OWNER = 'create_vaki_set_owner',
  CREATE_VAKI_SET_TERMS = 'create_vaki_set_terms',
  CREATE_VAKI_COMPLETE = 'create_vaki_complete',
  CREATE_VAKI_SUCCEED = 'create_vaki_succeed',
  CREATE_VAKI_FAILED = 'create_vaki_failed'
}
