import { Reward, SubscriptionPlan } from '../vakis';

export const sortRewards = (
  { order = 0, claimed = 0, quantityAvailable = Infinity }: Reward,
  {
    order: orderB = 0,
    claimed: claimedB = 0,
    quantityAvailable: quantityAvailableB = Infinity,
  }: Reward,
) => {
  const orderDifference = order - orderB;
  const isEmptyA = quantityAvailable - claimed <= 0;
  const isEmptyB = quantityAvailableB - claimedB <= 0;
  if ((isEmptyA && isEmptyB) || (!isEmptyA && !isEmptyB)) {
    return orderDifference;
  } else {
    return isEmptyB ? -1 : 1;
  }
};

export const sortSubscriptions = (
  { order = 0 }: SubscriptionPlan,
  { order: orderB = 0 }: SubscriptionPlan,
) => order - orderB;
